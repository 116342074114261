import React, {  useEffect, useState } from "react";
import modele from "./transfert.xls";
import modeles from "./transfer.csv";
import { Link } from "react-router-dom";
import { FetchData } from "../common/services/httpServices";
import { PostData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { useFormData } from "../common/utils/hooks";
import {  ActivateTwoAuth } from "../common/auth";
import { encryptStorage } from "../common/services/encryptData";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

import { Spinner } from "./spinner.component";
import Wrapper from "./sectionWrapper.component";

export default function BulkComponent() {
  // const [toggle, setToggle] = useState(0);

  // const [myAmount, setMyAmount] = useState(0);
  // const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [failed, setFailed] = useState(false);

  // const [data, handleInput] = useFormData();
  // const [info, setInfo] = useState([]);

  // const [Infocountries, setInfocountries] = useState([]);
  // const [doloading, setDoLoading] = useState(true);
  // const [listOperators, setlistOperators] = useState([]);
  const [chosenPays, setchosenPays] = useState("BENIN");

  // const [network, setNetwork] = useState();

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      // setInfocountries(res);
      // setDoLoading(false);
      ListOfOperator({ target: { value: res.data.datas.items[0] } });
    });
  };

  useEffect(() => {
    countries();
  }, []);

  function ListOfOperator(pays) {
   
    if (pays.target) {
      FetchData(
        Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
      ).then((res) => {
        setchosenPays(pays.target.value);
        // setlistOperators(res.data.datas.items);
      });
    }
  }

  function getAmout(operateur) {
    FetchData(
      Endpoints.User.getAvailableAmount +
        "?country=" +
        chosenPays +
        "&operator=" +
        operateur.target.value
    ).then((res) => {
      // setMyAmount(res.data.datas.amount);
      // setLoading(true);
    });
  }

  function handleChange(event) {
    // console.log("voila toi nous maj",event.target.value);
    getAmout(event);
    // setNetwork(event.target.value);
  }

  // onChange={(e) => getAmout(e)}

  return (
    <div className="">
      <Multiple />
    </div>
  );
}

function Multiple() {
  const [data, handleInput] = useFormData();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [ fileType, setFileType ] = useState("")

  // console.log("usemessage succ", userMessage);

  const twofa = encryptStorage.getItem("qosUser")?.isUsing2Fa;

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!selectedFile) {
      // alert("Please first select a file");
      setLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please first select a file",
      });
      return;
    } else if (twofa === false || twofa === null) {
      setLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please! activate 2AF authenticator first",
      });
      return;
    }

    setShow(true);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // console.log("data.code", data.code);
  // console.log("fileType", fileType);

  const handlFileType = (e) => {
    setFileType(e.target.value);
  };



  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);

    //   if (!selectedFile) {
    //     // alert("Please first select a file");
    //     setLoading(false)
    //     const Toast = Swal.mixin({
    //       toast: true,
    //       position: 'top',
    //       showConfirmButton: false,
    //       timerProgressBar: true,
    //       timer: 7000,
    //       didOpen: (toast) => {
    //         toast.addEventListener('mouseenter', Swal.stopTimer)
    //         toast.addEventListener('mouseleave', Swal.resumeTimer)
    //       }
    //     })

    //     Toast.fire({
    //       icon: 'error',
    //       title: "Please first select a file"
    //     })
    //     return;
    //  }
    // const formData = new FormData();
    // formData.append("file", selectedFile);
    const formData = new FormData();
    formData.append("file", selectedFile);
    // console.log("status formData: ", formData);
    // formData.append("name", name);
    // formData.append("file", selectedFile);

    PostData(
      `${ fileType === "Xls" ?  Endpoints.Transfer.bulk_transfert_momo_Excel + "?code=" + data.code : Endpoints.Transfer.bulkTransferMomo + "?code=" + data.code }`,
      formData,
      {
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data; boundary=2000",
          accept: "*/*",
        },
      }
    )
      .then((res) => {
        console.log("status res: ", res);
        setLoading(false);
        // alert("File Upload success");
        if (
          res.data.responseCode === "00" &&
          res.data?.datas?.totalSuccess >= 1
        ) {
          let message = res.data.responseMessage;
          let totalSuccess = res.data?.datas?.totalSuccess;
          let totalFailed = res.data?.datas?.totalFailed;
          let total = res.data?.datas?.total;
          handleClose();
          //    const Toast = Swal.mixin({
          //    toast: true,
          //    position: 'top',
          //    showConfirmButton: false,
          //    timerProgressBar: true,
          //   //  timer: 7000,
          //    didOpen: (toast) => {
          //      toast.addEventListener('mouseenter', Swal.stopTimer)
          //      toast.addEventListener('mouseleave', Swal.resumeTimer)
          //    }
          //  })
          Swal.fire({
            icon: "success",
            title: message,
            text: `Total success: ${totalSuccess}, Total Failed: ${totalFailed}, Total: ${total}`,
            // footer: '<a href="">Why do I have this issue?</a>'
          });

          //  Toast.fire({
          //    icon: 'success',
          //    title: message
          //  })
          //  setTimeout(() =>{
          //   handleClose()
          // } , 9000)
          setUserMessage(null);
        } else if (
          res.data.responseCode === "00" &&
          res.data?.datas?.totalFailed >= 1
        ) {
          let message = res.data.responseMessage;
          let totalSuccess = res.data?.datas?.totalSuccess;
          let totalFailed = res.data?.datas?.totalFailed;
          let total = res.data?.datas?.total;
          handleClose();
          //    const Toast = Swal.mixin({
          //    toast: true,
          //    position: 'top',
          //    showConfirmButton: false,
          //    timerProgressBar: true,
          //   //  timer: 7000,
          //    didOpen: (toast) => {
          //      toast.addEventListener('mouseenter', Swal.stopTimer)
          //      toast.addEventListener('mouseleave', Swal.resumeTimer)
          //    }
          //  })
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: `Total success: ${totalSuccess}, Total Failed: ${totalFailed}, Total: ${total}`,
            // footer: '<a href="">Why do I have this issue?</a>'
          });

          //  Toast.fire({
          //    icon: 'success',
          //    title: message
          //  })
          //  setTimeout(() =>{
          //   handleClose()
          // } , 9000)
          setUserMessage(null);
        } else if (res.data.responseStatus === false) {
          let message = res.data.responseMessage;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timerProgressBar: true,
            // didOpen: (toast) => {
            //   toast.addEventListener('mouseenter', Swal.stopTimer)
            //   toast.addEventListener('mouseleave', Swal.resumeTimer)
            // }
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: message,
          });
          setUserMessage(null);
        }
      })
      .catch((err) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          // didOpen: (toast) => {
          //   toast.addEventListener('mouseenter', Swal.stopTimer)
          //   toast.addEventListener('mouseleave', Swal.resumeTimer)
          // }
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Contact Qos support team",
        });
        setUserMessage(null);
      });
  };

  // console.log("encript data token :", encryptStorage.getItem("qosToken"))
  // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
  // console.log("encript qosUser :", encryptStorage.getItem("qosUser"))
  // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
  // console.log("encript data status :", encryptStorage.getItem("qosUser")?.email)
  // console.log("encript data  :", encryptStorage.getItem("qosUser"))

  //     if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="1.0" ) {
  //       // console.log("encript data token :", encryptStorage.getItem("qosToken"))
  //       history.push("/dashboard");

  // console.log("data username :", username)

  const username = encryptStorage.getItem("qosUser")?.email;

  // console.log("data username :", username)

  const cara = { username };

  const datas = { ...data, ...cara };

  // console.log("data data  data:", datas)
 


  async function handleCode(e) {
    e.preventDefault();
    setLoading(true);

    // console.log("data :", datas)
    try {
      // data.isRememberMe = true;

      const { data: user, status } = await ActivateTwoAuth(datas);
      // console.log("status status : ", status)

      // console.log("datas.dat  : ", data)
      // console.log("user user: ", user.responseCode)
      // console.log("encript data token :", encryptStorage.getItem("qosToken"))
      // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
      // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
      // console.log("encript data  :", encryptStorage.getItem("qosUser"))

      if (user.responseMessage === "Successful") {
        // console.log("encript data token :", encryptStorage.getItem("qosToken"))
        // history.push("/");

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
        setUserMessage(user.responseMessage);
      } else {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          // title: 'Error!',
          text: "Please use real generated code number",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
    } catch (error) {
      // alert("error");
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "Please use real generated code number",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
    setLoading(false);
  }

  return (
    <div className="">
      <section className="h-full  md:h-full flex justify-center">
        <Wrapper className="bg-white w-full  lg:w-1/2 md:mx-20 ">
          <div className=" mt-10">
            <p
              className="pt-2 pl-5 text-semibold "
              style={{ fontSize: 20, color: "#45464E" }}
            >
              New bulk transfer
            </p>
          </div>
          <div></div>
          <div className=" p-5 ">
            {/* <div className="mx-auto w-full   mt-8">
              <span className="block">Select account*</span>
              <select
                style={styles.input}
                className="w-full"
                name="country"
                onChange={handleInput}
              >
                <option disabled selected hidden>
                  MTN to MTN{" "}
                </option>
                <option value="BENIN">MTN to MTN</option>
                <option value="TOGO">MTN to MOOV</option>
                <option value="IVORY_COST">MOOV to MOOV</option>
                <option value="IVORY_COST">MOOV to MTN</option>
                <option value="IVORY_COST">BANK to BANK</option>
              </select>
            </div> */}
            <div className="border rounded mt-10 mb-2">
              <div className="m-5 flex-column">
                <p style={{ color: "#45464E" }}>How to upload file</p>
                <span style={{ color: "#45464E" }}>
                Select File type you want to use. 
                </span>
                <p style={{ color: "#45464E" }}>
                  Download a template below, Add your data to the template file,
                  then upload it here for processing.
                </p>
              </div>

              <div className="m-5 flex-column">
                <select onChange={handlFileType}>
                  <option >Select file type  </option>
                  <option value="Xls">Xls</option>
                  <option value="Csv">Csv</option>
                </select>
              </div>

              <div className="m-5">
                <Form.Control
                  type="file"
                  accept="csv, xls"
                  size="lg"
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <Link
              to={modeles}
              download="modele_bulk_transfer_csv"
              target="_blank"
              rel="noreferrer"
            >
              <p className="mb-2 text-qosblack">Download <span className="text-qosorange">CSV</span>  editable template</p>
            </Link>

            <Link
              to={modele}
              download="modele_bulk_transfer_xls"
              target="_blank"
              rel="noreferrer"
            >
              <p className="mb-5 text-qosblack">Download <span className="text-qosorange">XLS</span> editable template</p>
            </Link>

            <button
              disabled={isLoading}
              type="submit"
              className={`w-full h-12 flex justify-center items-center  rounded text-qosgray font-bold ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              } btn-sm md:max-w-full mx-auto mt-1`}
              variant="warning"
              size="lg"
              onClick={handleShow}
            >
              {isLoading ? (
                <Spinner className="border-qosorange h-4 w-4 mx-1" />
              ) : (
                "Send"
              )}
            </button>

            {/* <Button variant="primary" onClick={handleShow}>
          Launch static backdrop modal
        </Button> */}
          </div>
        </Wrapper>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div>
            {userMessage == null ? (
              <form
                id="stepForm"
                onSubmit={handleCode}
                className="overflow-x-hidden hideScroll text-center max-w-full w-5/6 md:w-11/12 mx-auto"
              >
                <h3 className="pt-2  text-qosTab">2FA verification</h3>

                <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                  <div className="text-left pl-4 w-full"></div>

                  <div className=" w-full text-left  gap-2"></div>
                </div>
                <div className="m-4">
                  <p className="pt-1 mb-4 text-qosTab">
                    Please enter your 2FA verification code here to complete
                    this transfer
                  </p>
                </div>
                <div className="flex bg-qoswhite border my-2 border-qosgraye gap-2 rounded justify-around">
                  {/* <label className="block">Code </label> */}
                  <input
                    className="bg-qoswhite border-none rounded"
                    name="code"
                    value={data.code}
                    onChange={handleInput}
                    required
                    placeholder="code"
                  />
                </div>

                <div className="font-bold text-qosblue  mx-auto flex justify-around items-center mt-4 mb-10 ">
                  <button
                    id="next"
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  rounded-md text-qosgray text-opacity-80 font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    }  btn-sm md:max-w-lg mx-auto mt-8`}
                  >
                    {isLoading ? (
                      <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
                    ) : (
                      <span className="text-msm" style={{ fontSize: "16px" }}>
                        Verify
                      </span>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <>
                <h3 className="p-2 text-center">
                  Click below to complete your transaction
                </h3>

                <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                  <div className="text-left pl-4 w-full"></div>

                  <div className=" w-full text-left  gap-2"></div>
                </div>
                <p className=" text-center">👇</p>
                <div className="font-bold text-qosblue  mx-auto flex justify-around items-center mt-4 mb-10 ">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  rounded-md text-qosgray text-opacity-80 font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    }  btn-sm md:max-w-lg mx-auto mt-8`}
                    variant="primary"
                    size="lg"
                    onClick={submitForm}
                  >
                    {isLoading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      "Confirm transfer"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const styles = {
  input: {
    width: "100%",
    // height:"42px",
    height: 50,
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
