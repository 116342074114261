export const paths = {
  login: "/",
  register: "/signup",
  PasswordReset:"/PasswordReset",
  dashboard: "/dashboard",
  pay:"/",
  simplepayment:"/qos/payment",
  MailComfirm:"/register/verify",
  Passreset:"/verify/reset-password",
  terms:"/terms",
  termse:"/termse",
  home: "/Getlastestupdate",
  Customers:"/Customers",
  Offer:"/Offer",
  ResponsePage:"/ResponsePage",
  AccountCreated:"/AccountCreated",
  resendemailverify:"/resendemailverify",
  getstarted:"/getstarted",
  status:"/status",
  typeofbusiness:"/typeofbusiness",
  individual:"/individual",
  register:"/register",
  transfers:"/transfers",
  Singletransfer:"/Singletransfer",
  Bulktransfer:"/Bulktransfer",
  MyStore:"/MyStore",
  TopUp:"/topup",
  TopUpPage:"/TopUpPage",
  jointeam:"/jointeam",
  // 404:"*",
  privacy: "/termsandconditions",
  transaction: {
    byPeriod: {
      label: "By period",
      path: "/transactions/byperiod",
    },
    statment: {
      label: "Transactions",
      path: "/transactions",
    },
  },
  transfer: {
    process: {
      label: "process",
      path: "/Payout",
      // path: "/transfer/process",ƒ
    },
    credentials: {
      label: "Credentials",
      path: "/transfer/credentials",
    },
  },
  paymentlink: "/paymentlink",
  paymentlinkdetails:"/paymentlinkdetails",
  setting: {
    general: "/settings",
    password: "/settings/password",
    merchantNumber: {
      label: "Merchant number",
      path: "/settings/merchantNumber",
    },
  },
  analytics: {
    label: "Analytics",
    path: "/analytics",
  },
  mailing: "/mailing",
  merchantNumber: {
    mtn: "/mtnmerchantnumber",
    moov: "/moovmerchantnumber",
  },
  lasttentransactions: "/lasttentransactions",
  transactionsbymultipleparameters: "/transactionsbymultipleparameters",
  transferttomultiplemtnnumbers: "/transferttomultiplemtnnumbers",
  transferttomultiplemoovnumbers: "/transferttomultiplemoovnumbers",
  mtntransactionsanalytics: "/mtntransactionsanalytics",
};
