// import { DashTable } from "../components/tables/dashtable.component";
// import { Spinner } from "../components/spinner.component";
// import { Switch } from "../components/switch.component";
// import { SelectionState } from "draft-js";
import { useState, useMemo, useEffect } from "react";
import { FetchData, PostData } from "../common/services/httpServices";
import { CheckStatusValue, Generate } from "../common/auth";

import { useFormData } from "../common/utils/hooks";
import axios from "axios";
import { Endpoints } from "../common/utils/endpoints";

import { isNull } from "lodash";
import { matchSorter } from "match-sorter";

import { makeCsv, getTableDataForExport } from "../common/utils/table";
import DatePicker from "react-flatpickr";
import {
  useTable,
  // useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import Table from "react-bootstrap/Table";
import { format } from "date-fns";
import GlobalFilter from "../components/GlobalFilter";
import { useLangContext } from "../components/translateContext";
import transaction from "../components/translation/transaction.json";
import Wrapper from "../components/sectionWrapper.component";
import { Spinner } from "../components/spinner.component";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { Icon } from "@iconify/react";
import { media } from "../common/libs/media";
import Modal from "react-bootstrap/Modal";
import "./table.css";
// import weeksToDays from "date-fns/weeksToDays";

// Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <div>
//       <input
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         min={2}
//         className="h-4 border-none md:w-40 text-mmd px-0 min-w-max"
//         style={{
//           height: "32px",
//           width: "40px",
//           border: "1px solid rgba(0,0,0,.2)",
//         }}
//         placeholder={`${count} search...`}
//       />
//     </div>
//   );
// }

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function Statements() {
  const [state, setstate] = useState(0);
  const { Lang } = useLangContext();
  const Infos = { msg: transaction[Lang] };

  return (
    <section className="p-4 ">
      {/* <div className="mt-14 mb-5 flex text-left gap-1">
        <p className=" " style={{ fontSize: 20, color: "#45464E" }}>
          {Infos.msg.Transactions}
        </p>
      </div> */}
      <div className="flex-column text-left gap-2">
        <h4 style={{ color: "#45464E" }} className="pt-4 ">
          {Infos.msg.Transactions}
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
        {Infos.msg.See}
        </span>
      </div>

      <header className="mt-14">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-24 sm:w-32 md:w-44  text-white h-full bg-qosorange  rounded-full p-2 md:px-4`}
          ></div>
          <button
            onClick={() => setstate(0)}
            className={`transition-all duration-500 ${
              state === 0 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
          >
            {Infos.msg.Transactionslo}
          </button>
          <button
            onClick={() => setstate(1)}
            className={`transition-all duration-500 ${
              state === 1 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
          >
            {Infos.msg.Rapport}
          </button>
        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <Statement />}
          {state === 1 && <Repport />}
          {/* {state === 0 && "gfgfgfgfgf"} */}
        </section>
      </div>
    </section>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

export function Statement() {
  const { qosLogoBlack } = media.Logos.qos;
  const [Operator, setOperator] = useState("MTN");

  // const [myAmount, setMyAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [failed, setFailed] = useState(false);
  const { Lang } = useLangContext();
  const Infos = { msg: transaction[Lang] };

  // const [data, handleInput] = useFormData();
  // const [info, setInfo] = useState([]);

  const [Infocountries, setInfocountries] = useState({});
  const [doloading, setDoLoading] = useState(true);
  const [listOperators, setlistOperators] = useState({});
  const [chosenPays, setchosenPays] = useState("");

  const [ status, setStatus ] = useState({})

  const [network, setNetwork] = useState("");
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [ newData, setNewData ] =useState({});
  // const [isLoading, setLoading] = useState(false);
  // const btnMsg = Button;
  const handleOpen = () => {
    setShow(true);
    setIsActive(!isActive);
  };
  const handleCclose = () => {
    setIsActive(!isActive);
    setShow(false);
  };

  // console.log("le newData ==>",newData)

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      // console.log("mon pays", res);
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res?.data?.datas?.items[0] } });
    });
  };

  // const [ Data, setData] = useState([]);
  useEffect(() => {
    countries();
  }, []);

  // const retro = doloading?""

  // console.log("mon pays Infocountries",Infocountries)

  function ListOfOperator(pays) {
    // console.log('yoo')
    // console.log("pays",pays)
    // console.log("pays.target.value",pays.target.value)

    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;
    // console.log("lopetateur", lopetateur)

    if (pays.target) {
      FetchData(
        // Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
        Endpoints.Subscriptions.getOperators + "?country=" + lopetateur
      ).then((res) => {
        // setchosenPays(pays.target.value);
        setchosenPays(lopetateur);
        setlistOperators(res);
      });
    }
  }

  async function handleChange(event) {
    // console.log("voila toi",event.target.value);
    setNetwork(event.target.value);
  }

  async function CheckStransactionStatus(payload) {
    // console.log('yoo le', payload)

   const value1 = payload.clientid
   const value2 = payload.clientrefno

    const dtaTrnstoverify = { "clientid" : value1 , "transref": value2 }
 
    try{
      const { data: status } = await CheckStatusValue(dtaTrnstoverify)  ;
      console.log("data", status)
      // console.log("data", status)
      // if (
      //   status === "00"
      // ) {
      let message = status.responseMessage;

      if (status.responseMessage === "L'opérateur MOOV n'existe pas ")
      {
        Swal.fire({
         
          icon: "warning",
          title: "Vérification du status non disponible pour cette opérateur",
          text: ` ${status.responseMessage }`,
          footer: '<a href="">Status de la transaction</a>'
        });
      } else{
        Swal.fire({
          icon: "success",
          title: message,
          text: ` ${status.responseMessage }`,
          footer: '<a href="">Status de la transaction</a>'
        });
      }
      // let totalSuccess = res.data?.datas?.totalSuccess;
      // let totalFailed = res.data?.datas?.totalFailed;
      // let total = res.data?.datas?.total;
      // handleClose();
      //    const Toast = Swal.mixin({
      //    toast: true,
      //    position: 'top',
      //    showConfirmButton: false,
      //    timerProgressBar: true,
      //   //  timer: 7000,
      //    didOpen: (toast) => {
      //      toast.addEventListener('mouseenter', Swal.stopTimer)
      //      toast.addEventListener('mouseleave', Swal.resumeTimer)
      //    }
      //  })
     


    }catch(error){
      return error
    }
  }


  async function handleClick(origine){
    // console.log("origine", origine)
    setNewData(origine);
    handleOpen();
  }

  const [RequestType, setRequestType] = useState(0);
  const [Data, setData] = useState([]);
  const [DateRange, setDateRange] = useState(null);
  // const [Loading, setLoading] = useState(false);
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  let value =
    RequestType === 0
      ? "REQUESTPAYMENT"
       : RequestType === 1
      ? "MAKEDEPOSIT"
      : "REQUESTPAYMENT";

  let operateur = network;
  // Endpoints.User.getAvailableAmount+'?country='+chosenPays+'&operator='+operateur.target.value,

  useEffect(() => {
    setLoading(true);

    if (operateur != "") {
      if (RequestType === 0) {
        // console.log("=== req 0 :"+RequestType.toString())
        axios
          .get(
            Endpoints?.Transactions?.getUserSuccessfullRequestPayment +
              "?operator=" +
              operateur
            // (
            // {
            //   params: {
            //     operator: Operator,
            //   },
            // }
            // {
            //   params: {
            //    operator: Operator,
            //  },
            //  }
          )
          .then((res) => {
            // console.log("listes des request pay :", res);
            setData(res?.data?.datas?.items?.filter((trx) => !isNull(trx)));
            setLoading(false);
          });
      } else if (RequestType === 1) {
        // console.log("=== req 1 :"+RequestType.toString())
        axios
          .get(
            Endpoints?.Transactions?.getUserSuccessfullRefund +
              "?operator=" +
              operateur
            // {
            //   params: {
            //     clientId: getUser(`ID${Operator}`),
            //   },
            // }
            // {
            //   params: {
            //    operator: Operator,
            //  },
            //  }
          )
          .then((res) => {
            setData(res?.data?.datas?.items?.filter((trx) => !isNull(trx)));
            setLoading(false);
          });
      } else if (RequestType === 2) {
        // console.log("=== req 2 :"+RequestType.toString())
        axios
          .get(
            Endpoints?.Transactions?.getUserSuccessfullDeposit +
              "?operator=" +
              operateur
            // {
            //   params: {
            //     operator: Operator,
            //   },
            // }
          )
          .then((res) => {
            setData(res?.data?.datas?.items?.filter((trx) => !isNull(trx)));
            setLoading(false);
          });
      }
    }
  }, [network, RequestType]);

  useEffect(() => {
    setLoading(true);
    if (operateur !== "") {
      if (DateRange) {
        axios
          .get(
            Endpoints?.Transactions?.getUsertransactionsByClientAndByPeriod +
              "?begin=" +
              DateRange.begin +
              "&country=" +
              chosenPays +
              "&end=" +
              DateRange.end +
              "&operator=" +
              operateur +
              "&type=" +
              value
            // {
            //   params: {
            //     // clientId: getUser(`ID${Operator}`),
            //     begin: DateRange.begin,
            //     end: DateRange.end,
            //   },
            // }
          )
          .then((res) => {
            // console.log("mes looooo", res)
            setData(res?.data?.datas?.items?.filter((trx) => !isNull(trx)));
            // console.log("mes looooo DEDE", setData)
            setLoading(false);
          });
      }
    }
  }, [DateRange]);

  const columns = useMemo(
    () => [
      {
        name: `${Infos.msg.Transac}`,
        accessor: "clientrefno",
      },
      {
        name: `${Infos.msg.Reference}`,
        accessor: "momtransaction_id",
      },

      {
        name: `${Infos.msg.Amount}`,
        accessor: "amount",
      },
      {
        name: `${Infos.msg.Date}`,
        accessor: "trxndate",
        Cell: ({ value }) => {
          return format(new Date(value), "dd-MM-yyyy");
        },
      },
      {
        name: `${Infos.msg.Phone}`,
        accessor: "sendersmsisdn",
      },
      {
        name: `${Infos.msg.Operation}`,
        accessor: "operationtype",
      },
      {
        name: `${Infos.msg.Message}`,
        accessor: "responsemessage",
      },
      {
        name: `${Infos.msg.Action}`,
        accessor: "Action",
        Cell: ({ row }) => (
          <div className="flex justify-center items-center" onClick={() => handleClick(row.original)}>
            <Icon icon="ph:eye-bold" height={25} style={{ color: "orange" }} className="cursor-pointer" />
          </div>
        ),
      },

      //  {
      //   name: `${Infos.msg.Time}`,
      //   accessor: "responsetime",
      //   Cell: ({ value }) => {
      //     return format(new Date(value), "H:mm  ");
      //   },
      // }, 

      // {
      //   name: `${Infos.msg.Client}`,
      //   accessor: "clientid",
      // },
      // {
      //   name: `${Infos.msg.Reference}`,
      //   accessor: "clientrefno",
      // },

      // {
      //   name: "PROCESSING NUMBER",
      //   accessor: "processingnumber",
      // },
    ],
    []
  );
  const data = useMemo(() => Data, [Operator, Data, RequestType]);
  // console.log("nouvele donnee a check" , data)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    // visibleColumns,
    // preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
      filterTypes,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );
  const verifySome = (value) =>{
    console.log("clicked button", value)
  }

  return (
    <section>
      {/*   <div className="mb-5 mt-5 max-w-screen-xl mx-auto">
        <Switch setOperator={setOperator} />
      </div> */}
      <div className="w-full mt-10 ">
        <header className="flex flex-wrap p-2 items-center justify-center lg:justify-between py-2">
          <div className=" grid grid-cols-1  md:flex  md:gap-4 justify-start">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div>
              <select
                value={RequestType}
                style={styles.input}
                onChange={(e) => setRequestType(e.target.value * 1)}
                className="h-8 text-mmd text-qosText px-4 md:w-40 min-w-max"
              >
                <option selected value="0">
                  Request payment
                </option>
                {/* {network === "MTN" && <option value="1">Refund</option>} */}
                {network === "CARD" ? "" : <option value="2">Deposit</option>}
              </select>
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            <div className="mx-2">
              <DatePicker
                style={styles.input}
                // style={{ height: "32px", width: "210px" }}
                className="w-full text-msm font-semibold"
                onChange={(val, value) => {
                  if (val.length > 1) {
                    setDateRange({
                      begin: format(val[0], "Y-MM-dd"),
                      end: format(val[1], "Y-MM-dd"),
                    });
                  }
                }}
                // static: true, for  option
                options={{
                  mode: "range",
                }}
                placeholder="Choose the date range"
              />
            </div>
            <div className="mt-1">
              <button
                type="button"
                style={styles.input}
                className="text-mST h-22 p-2 bg-qosorange  rounded-sm text-white mx-2"
                onClick={() =>
                  makeCsv(
                    getTableDataForExport(data, columns),
                    `transactions.csv`
                  )
                }
              >
                Export to CSV
              </button>
            </div>
          </div>

          {/* <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        /> */}
        </header>
      </div>


      <div className="max-w-full xl relative overflow-hidden mt-5 bg-white mx-auto my-2 sm:rounded-lg ">
        {/* <DashTable   /> */}
        <div className="border p-4">
          <div className="max-w-full xl relative overflow-hidden  mt-9 mx-auto my-2 sm:rounded-lg ">
            <div className="flex gap-4 justify-end sm:rounded-lg ">
              <div>
                {doloading ? (
                  <span className="text-qosText ">Loading...</span>
                ) : (
                  <select
                    id="operators"
                    onChange={handleChange}
                    name="operators"
                    autoComplete="operators"
                    className="h-18  bg-white bg-opacity-70 text-black text-mmd px-4 md:w-40 min-w-max "
                  >
                    <option value="" selected hidden className="text-black">
                      {Infos.msg.selectOp}
                    </option>
                    {/* <option value=""  selected hidden >{listOperators[0]}</option> */}
                    {listOperators?.data?.datas?.items?.map((item) => (
                      <option key={item.index}>{item}</option>
                    ))}
                  </select>
                )}
              </div>

              <div>
                {/* px-4 md:w-40 min-w-max */}

                <select
                  onChange={(e) => ListOfOperator(e)}
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd "
                >
                  {/* <option value="" disabled selected hidden className="text-black">
                BENIN
              </option> */}
                  {doloading
                    ? "loading.."
                    : Infocountries?.data?.datas?.items?.map((item) => {
                        // console.log("Infocountries Infocountries", item);
                        return (
                          <option
                            className="text-qosorange m-1 w-10"
                            key={item.index}
                          >
                            {item === "TOGO"
                              ? "🇹🇬"
                              : item === "BENIN"
                              ? "🇧🇯"
                              : item === "IVORY_COST" || item === "IVORY_COAST"
                              ? "🇨🇮"
                              : ""}
                          </option>
                        );
                      })}
                </select>
              </div>
            </div>
          </div>
          {/* <header className="flex flex-wrap p-2 items-center justify-center lg:justify-around py-2">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div>
              <select
                value={RequestType}
                style={styles.input}

                onChange={(e) => setRequestType(e.target.value * 1)}
                className="h-8 text-mmd text-qosText px-4 md:w-40 min-w-max"
              >
                <option selected value="0">
                  Request payment
                </option>
                {network === "MTN" && <option value="1">Refund</option>}
                {network === "CARD" ? "" : <option value="2">Deposit</option>}
              </select>
            </div>
            <div className="mx-2">
              <DatePicker
               style={styles.input}
                // style={{ height: "32px", width: "210px" }}
                className="w-full text-msm font-semibold"
                onChange={(val, value) => {
                  if (val.length > 1) {
                    setDateRange({
                      begin: format(val[0], "Y-MM-dd"),
                      end: format(val[1], "Y-MM-dd"),
                    });
                  }
                }}
                // static: true, for  option
                options={{
                  mode: "range",
                }}
                placeholder="Choose the date range"
              />
            </div>

            

            <button
              type="button"
              style={styles.input}
              className="text-mST h-22 p-2 bg-qosorange  rounded-sm text-white mx-2"
              onClick={() =>
                makeCsv(
                  getTableDataForExport(data, columns),
                  `transactions.csv`
                )
              }
            >
              Export to CSV
            </button>
          </header> */}
          <div className="overflow-x-auto hideScroll border ">
            {/*  {Loading && (
          <div className="h-full w-full grid absolute bg-qosblue bg-opacity-10 items-center justify-center">
            <Spinner className="border-qosblue h-8 w-8 mx-1 -mt-20" />
          </div>
        )} */}

            <table id="customers" {...getTableProps()}>
              <thead className="font-extrabold ">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        style={{ fontSize: 18 }}
                        scope="col"
                        className="px-4 py-4 relative text-msm text-qosdark text-opacity-100  tracking-tighter leading-3 truncate text-center"
                        {...column.getHeaderProps()}
                      >
                        {column.render("name")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span className="fi font-bold text-mlg fi-rr-angle-small-down block"></span>
                          ) : (
                            <span className="fi font-bold text-mlg fi-rr-angle-small-up block"></span>
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-qosgraye"
              >
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        const { value } = cell;
                        // console.log("value",cell.render("Cell"))
                        // console.log("value value",value)
                        const colors =
                          value === "SUCCESSFUL" || value === "SUCCESS"
                            ? "green"
                            : value === "FAILED"
                            ? "red"
                            : value === "PENDING"
                            ? "blue"
                            : "";
                        const color =
                          value === "SUCCESSFUL" || value === "SUCCESS"
                            ? "white"
                            : value === "FAILED"
                            ? "white"
                            : value === "PENDING"
                            ? "white"
                            : "";
                        const font =
                          value === "SUCCESSFUL"
                            ? "12px"
                            : value === "FAILED"
                            ? "12px"
                            : value === "PENDING"
                            ? "12px"
                            : "";
                        return (
                          <td
                            className="px-6 py-4 whitespace-nowrap"
                            {...cell.getCellProps()}
                          >
                            <div
                              className="text-msm text-center font-medium text-qosdark truncate md:px-4"
                              style={{
                                backgroundColor: `${colors}`,
                                padding: "1px",
                                // width:auto,
                                // maxWidth:"200px",
                                // // max-width: 300px,
                                // textOverflow:"ellipsis",
                                // display: "-webkit-box",
                                // display: -webkit-box;

                                color,
                                fontSize: `${font}`,
                                borderRadius: "50px",
                              }}
                            >
                              {cell.render("Cell")}

                              {value == "" ? (
                                <button
                                  onClick={() =>
                                    CheckStransactionStatus(row.original)
                                  }
                                  className="bg-qosorange border rounded-full text-msm text-white  sm:w-32 md:w-44 font-medium   truncate px-1 md:px-4"
                                >
                                  Update status
                                </button>
                              ) : (
                                ""
                              )}

                             
                              
                           
                            </div>
                        
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center md:justify-end bg-coolGray-50 py-2">
            <div className="mx-2 ">
              <select
                defaultValue={pageSize}
                className="h-8 text-mmd text-qosText px-1 md:px-4 md:w-40 min-w-max"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex justify-around max-w-max items-center mt-1 mr-2 h-8">
              <button
                className={`${
                  !canPreviousPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300 delay-200`}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="fi fi-rr-angle-double-small-left"></span>
              </button>{" "}
              <button
                className={`${
                  !canPreviousPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="fi fi-rr-angle-small-left"></span>
              </button>
              <span className="min-w-max mx-4">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <button
                className={`${
                  !canNextPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300`}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <span className="fi fi-rr-angle-small-right"></span>
              </button>{" "}
              <button
                className={`${
                  !canNextPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300 delay-200`}
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="fi fi-rr-angle-double-small-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>

        <div className="w-full px-4 justify-center items-center text-center">
          <p className="text-semibold underline text-3xl pb-4">Détails de la transaction</p>
        </div>
        <div>
         
           <Modal.Body>
           { newData == "" || newData == undefined || newData == null ? " " : (
            <div className="w-full flex justify-center items-center">
              <div className="text-left">
                <h5 className="py-1 ">Amount : <span className="text-qosorange px-1">{newData.amount}</span></h5>
                <h5 className="py-1 ">Channel : <span className="text-qosorange px-1">{newData.channel}</span></h5>
                <h5 className="py-1 ">Clientid : <span className="text-qosorange px-1">{newData.clientid}</span></h5>
                <h5 className="py-1 ">Transref : <span className="text-qosorange px-1">{newData.clientrefno}</span></h5>
                <h5 className="py-1 ">Currency : <span className="text-qosorange px-1">{newData.currency}</span></h5>
                {/* <h5 className="py-1 ">Fee : <span className="text-qosorange px-1">{newData.fee}</span></h5> */}
                <h5 className="py-1 ">Operator_id : <span className="text-qosorange px-1">{newData.momtransaction_id}</span></h5>
                <h5 className="py-1 ">Narration : <span className="text-qosorange px-1">{newData.narration}</span></h5>
                {/* <h5 className="py-1 ">Net_amount : <span className="text-qosorange px-1">{newData.net_amount}</span></h5> */}
                <h5 className="py-1 ">Operationtype : <span className="text-qosorange px-1">{newData.operationtype}</span></h5>
                {/* <h5 className="py-1 ">Processingnumber : <span className="text-qosorange px-1">{newData.processingnumber}</span></h5> */}
                
                <h5 className="py-1 "> Date : <span className="text-qosorange px-4">{newData.trxndate}</span></h5>
                {/* <h5 className="py-1 "> Receiversfirstname : <span className="text-qosorange px-1">{newData.receiversfirstname}</span></h5> */}
                  
                {/* <h5 className="py-1 "> Receiverslastname : <span className="text-qosorange px-1">{newData.receiverslastname}</span></h5> */}
                {/* <h5 className="py-1 "> Receiversmsisdn : <span className="text-qosorange px-1">{newData.receiversmsisdn}</span></h5> */}
                {/* <h5 className="py-1 "> Response : <span className="text-qosorange px-1">{newData.response}</span></h5> */}
                {/* <h5 className="py-1 "> Responsemessage : <span className="text-qosorange px-1">{newData.responsemessage}</span></h5> */}
                <h5 className="py-1 "> Responsetime: <span className="text-qosorange px-1">{newData.responsetime}</span></h5>
                <h5 className="py-1 "> Sendersfirstname : <span className="text-qosorange px-1">{newData.sendersfirstname}</span></h5>
                <h5 className="py-1 "> Senderslastname : <span className="text-qosorange px-1">{newData.senderslastname}</span></h5>
                <h5 className="py-1 "> Sendersmsisdn : <span className="text-qosorange px-1">{newData.sendersmsisdn}</span></h5>
                <h5 className="py-1 "> Specialfield1 : <span className="text-qosorange px-1">{newData.specialfield1}</span></h5>
                <h5 className="py-1 "> Status : <span className={ `${newData.status == "FAILED" ?"text-qosred": "text-qosgreen"} px-1` }>{newData.status}</span></h5>
                {/* <h5 className="py-1 "> Transactionid : <span className="text-qosorange px-1">{newData.momtransaction_id}</span></h5> */}
               
              </div>


            </div>)}

           </Modal.Body>


        </div>
      </Modal>
    </section>
  );
}

export function Repport() {
  const [response, setResponse] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loading, setIsLoading] = useState(true);

  const { Lang } = useLangContext();
  const Infos = { msg: transaction[Lang] };

  const [listCredential, setListCredential] = useState([]);
  const [loadingCredential, setLoadingCredential] = useState(true);
  const [newCountry, setNewCountry] = useState("");

  const [data, handleInput] = useFormData();
  const [info, setInfo] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");

  const [datas, setDatas] = useState({
    client_id: "",
  });
  // console.log("Mon datas", datas);
  const annee = parseInt(data.annee);
  const clientId = datas.client_id;
  const mois = parseInt(data.mois);
  const type = data.type;
  // console.log("annee", annee)
  // console.log("mois", mois)
  // console.log("clientId", clientId)
  // console.log("type", type)

  // console.log("la reponse du file  :", response)
  // console.log("listCredential :", listCredential)

  // Endpoints.Transactions.getUserSuccessfullRefund+'?operator='+operateur,

  const inf = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfo(res);
      setIsLoading(false);
      ListOfCredential({ target: { value: res.data.datas.items[0] } });
    });
  };

  useEffect(() => {
    inf();
  }, []);

  function ListOfCredential(pays) {
    setLoadingCredential(true);

    setNewCountry(pays.target.value);

    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;

    // console.log("pays :",newCountry)
    if (pays.target) {
      FetchData(
        // Endpoints.Credentials.get + "?country=" + pays.target.value
        Endpoints.Credentials.get + "?country=" + lopetateur
      ).then((res) => {
        setListCredential(res);
        setLoadingCredential(false);
      });
    }
  }

  const handleOperatorChange = (event) => {
    const selectedOperator = event.target.value;
    setSelectedOperator(selectedOperator);

    // Find the data object for the selected operator
    const selectedData = listCredential?.data?.datas?.items?.find(
      (operatorData) => operatorData.operator === selectedOperator
    );

    // Update the client_id in the state
    setDatas((prevData) => ({
      ...prevData,
      operator: selectedData.operator,
      client_id: selectedData.client_id,
    }));
  };

  async function handleSubmite(e) {
    e.preventDefault();
    setLoading(true);

    // console.log("data data :", data)

    try {
      // data.isRememberMe = true;

      PostData(
        Endpoints.Rapport.generateRapport +
          `${clientId}/` +
          `${mois}/` +
          `${annee}` +
          "?type=" +
          data.type
      ).then((res) => {
        setResponse(res);
        // console.log("ross", res);
      });

      if (response?.data != undefined) {
        Swal.fire({
          position: "top-end",
          // title: error,
          text: "success",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      } else if (response?.data === undefined) {
        Swal.fire({
          position: "top-end",
          // title: error,
          text: "User profile not found",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      } else {
        Swal.fire({
          position: "top-end",
          // title: error,
          text: "User profile not found",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }

      setLoading(false);
    } catch (error) {
      // alert("error");
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "User profile not found",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
    setLoading(false);
  }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     // Replace 'your_api_endpoint' with your actual API endpoint
  //     const response = await axios.post(
  //       Endpoints.Rapport.generateRapport+`${clientId}/`+`${mois}/`+`${annee}`+'?type='+data.type,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         responseType: 'blob',
  //       }
  //     );
  //     setLoading(false);

  //     console.log("response rapport",response)
  //     console.log("response downloading",response.data)
  //     const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  //     console.log("pdfBlob pdfBlob",pdfBlob)

  //     // Replace 'your_file_name.extension' with the desired file name and extension
  //     saveAs(response.data, 'rapport.pdf');
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Error downloading file:', error);
  //   }
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       Endpoints.Rapport.generateRapport+`${clientId}/`+`${mois}/`+`${annee}`+'?type='+data.type,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  //     setLoading(false);
  //     console.log("response.data", response.data)

  //     // Convert the response data (PDF content) to a Blob
  //     // const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  //     const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  //     saveAs(pdfBlob, 'rapport.pdf');
  //     // Replace 'your_file_name.pdf' with the desired file name for the downloaded PDF
  //     // saveAs(response.data, 'rapport.pdf');
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Error downloading file:', error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Replace 'your_api_endpoint' with your actual API endpoint
      const url =
        Endpoints.Rapport.generateRapport +
        `${clientId}/` +
        `${mois}/` +
        `${annee}` +
        "?type=" +
        data.type;

      // Use fetch API to make the POST request
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // You may need to pass the request body here if required by the API
        // body: JSON.stringify({...}),
      });

      // console.log("response statment",response)

      if (!response.ok) {
        Swal.fire({
          position: "top-end",
          // title: error,
          text: "Please check your information",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
      } else if (response.ok) {
        // Convert the response data (PDF content) to a Blob
        Swal.fire({
          position: "top-end",
          // title: error,
          text: "SUCCESFULL",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        const pdfBlob = await response.blob();
        if(data.type === "excel"){

          saveAs(pdfBlob, "rapport.xlsx");
        }else{
          saveAs(pdfBlob, "rapport.pdf");
        }
        setLoading(false);
      }

      // Save the file using file-saver
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "Contact Support",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
  };

  return (
    <div className="w-full   justify-center align mt-10">
      <Wrapper className="bg-white border-sm py-4 border-qosgrain">
        <div className="bg-white  border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2   ">
          <div className="w-full p-4  flex bg-white justify-center items-center ">
            <span className="text-center " style={{ fontSize: 32 }}>
              {Infos.msg.generate}
              {/* <span className="text-qosorange text-opacity-95">
              Rapport
            </span> */}
            </span>
            {/* <button onClick={downloadFile}>Download PDF</button> */}
          </div>
          <div className="mx-auto  lg:w-8/12 mt-4  flex justify-end ">
            <div>
              {loading ? (
                <select
                  id="country"
                  name="country"
                  autoComplete="country"
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
                >
                  <option className="text-white m-1">Country</option>
                </select>
              ) : (
                <select
                  onChange={(e) => ListOfCredential(e)}
                  id="country"
                  name="country"
                  autoComplete="country"
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
                >
                  {/* <option value="" 
               disabled
               selected
               hidden
               className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
              >
              Country
              </option> */}
                  {info?.data?.datas?.items?.map((item) => (
                    <option key={item.index}>
                      {item === "TOGO"
                        ? "🇹🇬"
                        : item === "BENIN"
                        ? "🇧🇯"
                        : item === "IVORY_COST" || item === "IVORY_COAST"
                        ? "🇨🇮"
                        : ""}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          <div>{/* <button onClick={downloadPdf}>Download PDF</button> */}</div>
          <form onSubmit={handleSubmit} className="  w-full ">
            <div className="mx-auto  lg:w-8/12 mt-4">
              <span className="block">{Infos.msg.selectOp}</span>
              {/* <input
                style={styles.input}
                className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                name="clientId"
                value={data.clientId}
                onChange={handleInput}
                required
                placeholder="Put your client id"
              /> */}
              <select value={selectedOperator} onChange={handleOperatorChange}>
                <option value="">{Infos.msg.selectOp}</option>
                {listCredential?.data?.datas?.items?.map((operatorData) => (
                  <option
                    key={operatorData.operator}
                    value={operatorData.operator}
                  >
                    {operatorData.operator}
                  </option>
                ))}
              </select>
            </div>

            {/* <pre>{JSON.stringify(datas, null, 2)}</pre> */}

            <div className="mx-auto  lg:w-8/12 mt-4">
              <span className="block"> Year</span>

              <select
                style={styles.input}
                name="annee"
                required
                onChange={handleInput}
                className="text-qosText"
              >
                <option disabled selected hidden>
                  Select year{" "}
                </option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>

            <div className="mx-auto  lg:w-8/12 mt-4">
              <span className="block"> Month</span>

              <select
                style={styles.input}
                name="mois"
                required
                onChange={handleInput}
                className="text-qosText"
              >
                <option disabled selected hidden>
                  Select month{" "}
                </option>
                <option value="1">Janvier</option>
                <option value="2">Fevrier</option>
                <option value="3">Mars</option>
                <option value="4">Avril</option>
                <option value="5">Mai</option>
                <option value="6">Juin</option>
                <option value="7">Juillet</option>
                <option value="8">Août</option>
                <option value="9">Septembre</option>
                <option value="10">Octobre</option>
                <option value="11">Novembre</option>
                <option value="12">Decembre</option>
              </select>
            </div>

            <div className="mx-auto  lg:w-8/12 mt-4">
              <span className="block"> Select file type</span>

              <select
                style={styles.input}
                required
                name="type"
                onChange={handleInput}
                className="text-qosText"
              >
                <option disabled selected hidden>
                  Select file type{" "}
                </option>
                <option value="pdf">Pdf</option>
                {/* <option value="csv">Csv</option> */}
                <option value="excel">Excel</option>
              </select>
            </div>

            <button
              disabled={isLoading}
              style={styles.button}
              className={`w-full xl:w-8/12 lg:w-8/12  flex justify-center items-center  text-white text-opacity-80  ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              } bg-opacity-80 p-3 btn-sm  mx-auto mt-14`}
            >
              {isLoading ? (
                <Spinner className="border-qosorange h-4 w-4 mx-1" />
              ) : (
                <div className="flex gap-2  justify-center align-center">
                  <span>Download</span>
                  <span className="fi-rr-download mt-0.5"></span>
                </div>
              )}
            </button>
          </form>
        </div>
      </Wrapper>
    </div>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",
    height: 50,
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
