import React, { useState, useRef, useMemo } from "react";
import { credentialsHeader } from "../common/utils/table";
import { Switch } from "../components/switch.component";
import { Tablecompo } from "../components/table.component";
import { Table, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";
import { FetchData, PutData, PostData } from "../common/services/httpServices";
import { useLangContext } from "../components/translateContext";
import list from "../components/translation/whitelist.json";
import settings from "../components/translation/settings.json";
import { encryptStorage } from "../common/services/encryptData";

// import Table from "react-bootstrap/Table";

import { Endpoints } from "../common/utils/endpoints";
import useIsMobile, { useFormData } from "../common/utils/hooks";
import {
  generateCredentials,
  getUser,
  getUserTransCredentials,
} from "../common/utils/tools";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Spinner } from "../components/spinner.component";
import { media } from "../common/libs/media";
import "./setting.css"
import { CallbackUrl, DeleteCallBackUrl } from "../common/auth";
import ModalError from "../components/header.component";

export function Credentials() {
  const [Operator, setOperator] = useState("MTN");
  const [Updated, setUpdated] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [info, setInfo] = useState([]);
  const [selects, setSelects] = useState();
  const [loading, setLoading] = useState(true);
  const [listCredential, setListCredential] = useState([]);
  const [loadingCredential, setLoadingCredential] = useState(true);
  const [data, handleInput] = useFormData();
  const [isLoading, setIsLoading] = useState(false);
  const [doloading, setDoLoading] = useState(true);
  const [reg, setReg] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [credentials, setCredentials] = useState("");

  const [chosenPays, setchosenPays] = useState("");
  const [network, setNetwork] = useState("");
  const [Infocountries, setInfocountries] = useState({});
  const [tableD, setTableD] = useState([]);

  const [isUrlValid, setUrlValid] = useState(true);
  const [url, setUrl] = useState('');

  const [allCallback, setAllCallback] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [deleteCall, setDeleteCall] = useState({});



  const handleCclose = () => {
    setIsActive(!isActive);
    setShow1(false);
  };
  const handleCclose1 = ({}) => {
    setIsActive(!isActive);
    setShow(false);
  };

  const handleClose =() =>{
    setShow1(false);
  }
  
  const handleOpen = () => {
    setShow1(true);
    setIsActive(!isActive);
  };
  const handleOpen1 = () => {
    setShow(true);
    setIsActive(!isActive);
  };



  const [listOperators, setlistOperators] = useState({});

  const { qosLogoBlack } = media.Logos.qos;

  const modal = useRef();
  const modalLink = useRef();
  const modalQR = useRef();
  const linkBox = useRef();
  const boxToPrint = useRef();

  // console.log("allCallback jovial", allCallback);
  const { Lang } = useLangContext();
  const Info = { msg: list[Lang] };
  const Infos = { msg: settings[Lang] };


  useEffect(() => {
    inf();
    AllcallBack();
    countries();
  }, []);



  // useEffect(() => {
    const AllcallBack = () => {
      PutData(Endpoints.UsersProfile.AllcallBack).then((res) => {
        setAllCallback(res.data.responseMessage);
      });
    };

    const inf = () => {
      FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
        setInfo(res);
        setLoading(false);
        ListOfCredential({ target: { value: res.data.datas.items[0] } });
      });
    };

  const handleIplist = (clientID, ip) => {
    // Handle the delete action here
  
    console.log(`Delete IP: ${ip} for client: ${clientID}`);
  };


  const handleCalllist = (e, ip) => {
    // console.log("le e",e)
    // console.log("le ip",ip)
    // handleOpen1();
    setShow3(true)
    setDeleteCall({ e, ip });
  };

  // console.log("deleteCall deleteCall",deleteCall)

  const handleOpen2 = () => {
    setShow1(true);
    setIsActive1(!isActive1);
  };
  const handleCclose3 = () => {
    // setIsActive(!isActive);
    setShow3(false);
  };

  const datasDelete = {
    deleteCall,
  };

 

  async function handleDeleteCallBack(e) {
    e.preventDefault();
    setLoading(true);
    console.log("datasDelete",datasDelete)
    if (
      datasDelete.deleteCall.e === "" ||
      datasDelete.deleteCall.e === undefined
    ) {
      // setLoading(true);
      Swal.fire({
        position: "top-end",
        // title: "success!",
        text: "Ajouté votre Ip Adresse",
        icon: "warning",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    } else {
      try {
        const { data: user, status } = await DeleteCallBackUrl(datasDelete) ;

        if (status === 200) {
          Swal.fire({
            position: "top-end",
            title: "success!",
            text: "Successfull",
            icon: "success",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
            toast: true,
          });
          setTimeout(() => {
            // handleCclose2();
            AllcallBack();
            inf();
            handleCclose3();
          }, 4000);
        }

        setLoading(false);
      } catch (error) {
        // console.log("error server",error)
        setTimeout(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Internal Server Error",
            showConfirmButton: false,
            timer: 5500,
          });
        }, "100");
        setLoading(false);
      }
    }
    setLoading(false);
  }

  function ListOfCredential(pays) {
    setLoadingCredential(true);
    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;
    setNewCountry(pays.target.value);
    // console.log("pays :",newCountry)
    if (pays.target) {
      FetchData(
        Endpoints.Credentials.get + "?country=" + lopetateur
        // Endpoints.Credentials.get + "?country=" + pays.target.value
      ).then((res) => {
        setListCredential(res);
        setLoadingCredential(false);
      });
    }
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  function ListOfCredentials(MyQosKey) {
    if (MyQosKey) {
      PutData(
        Endpoints.UsersProfile.whitelisteApiAll + "?clientId=" + `${MyQosKey}`
      ).then((res) => {
        setTableD(res?.data?.responseMessage);
      });
    }
  }

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res?.data?.datas?.items[0] } });
    });
  };

  const handleInputChange = (e) => {
    const inputUrl = e.target.value;
    setUrl(inputUrl);
    setUrlValid(/^https:\/\/.+\..+/.test(inputUrl)); // Validate URL pattern
  };

  function ListOfOperator(pays) {
    // console.log('yoo')
    // console.log("pays", pays);
    // console.log("pays.target.value",pays.target.value)

    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;
    // console.log("lopetateur", lopetateur)

    if (pays.target) {
      FetchData(
        // Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
        Endpoints.Subscriptions.getOperators + "?country=" + lopetateur
      ).then((res) => {
        // setchosenPays(pays.target.value);
        // console.log("lopetateur res-->", lopetateur);
        setchosenPays(lopetateur);
        setlistOperators(res);
      });
    }
  }

  function handleChangess(event) {
    setNetwork(event.target.value);
    // setNewval(event.target.value);
    FetchData(Endpoints.Credentials.get + "?country=" + chosenPays).then(
      (res) => {
        // console.log("response contry",res)
        // setCredentials(res?.data?.datas?.items[2]?.client_id);
        if (res.data.datas.items.length !== 0) {
          // console.log("voila toi nous maj",event.target.value);
          for (let x in res.data.datas.items) {
            let elem = res.data.datas.items;
            if (network !== undefined) {
              if (
                event.target.value.toLowerCase() ===
                elem[x].operator.toLowerCase()
              ) {
                console.log("voila", elem[x].client_id);
                // setMonNumero(elem[x].merchantNumber);
                setCredentials(elem[x].client_id);
                ListOfCredentials(elem[x].client_id);
              }
            }
          }
        }
      }
    );
  }



  async function SubscribeToOffer({ service_name }) {
    setIsLoading(true);

    let SubscribeObject = service_name;
    // SubscribeObject.has_discount = has_discount;

    // let payload = JSON.stringify(SubscribeObject)  ;
    // console.log("Payload : ", SubscribeObject)

    try {
      const res = await PutData(
        Endpoints.UsersProfile.resetApiPassword +
          "?clientId=" +
          SubscribeObject,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("User dsdsds dsds :", res);
      if (res.data.responseMessage === "Reset Effectué avec succès") {
        let message = "A message has been sent to your email";
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setIsLoading(false);
        Toast.fire({
          icon: "success",
          title: message,
        });
        setTimeout(() => {
          closeModal();
        }, 5000);
      } else {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: message,
        });
      }
      return res;
    } catch (e) {}
  }

  const username = useMemo(
    () => getUserTransCredentials("username", Operator),
    [Operator, Updated]
  );
  const password = useMemo(
    () => getUserTransCredentials("pwd", Operator),
    [Operator, Updated]
  );

  const client_id = useMemo(() => getUser(`ID${Operator}`), [Operator]);


  const getData = [
    {
      key: "Username",
      value: "CNI",
    },
    {
      key: "Password",
      value: "14-10-2022",
    },
    {
      key: "CLIENT ID",
      value: "National ID card",
    },
    {
      key: "URL FOR REQUEST PAYMENT",
      value:
        Operator === "MTN"
          ? "https://qosic.net:8443/QosicBridge/user/requestpayment"
          : "https://qosic.net:8443/QosicBridge/user/requestpaymentmv",
    },
    {
      key: "URL FOR GET TRANSACTION STATE",
      value: "https://qosic.net:8443/QosicBridge/user/gettransactionstatus",
    },
  ];

  const List = [
    // {
    //   key: "USERNAME",
    //   value: username,
    // },
    // {
    //   key: "PASSWORD",
    //   value: password,
    // },
    // {
    //   key: "CLIENT ID",
    //   value: client_id,
    // },
    {
      key: "MTN URL FOR REQUEST PAYMENT",
      value: "https://api.qosic.net/QosicBridge/user/requestpayment",
    },
    {
      key: "MTN URL FOR REFUND",
      value: "https://api.qosic.net/QosicBridge/user/refund",
    },

    {
      key: "MOOV URL FOR REQUEST PAYMENT",
      value: "https://api.qosic.net/QosicBridge/user/requestpaymentmv",
      // newCountry === "BENIN"
      //     ? "Https://api.qosic.net/Qosbridge/user/requestpaymentmv"
      //     : "Https://api.qosic.net/Qosbridge/user/requestpaymentmv",
    },
    {
      key: "URL FOR GET TRANSACTION STATE",
      value: "https://api.qosic.net/QosicBridge/user/gettransactionstatus",
    },
  ];

  const ListTg = [
    {
      key: "URL TOGOCEL FOR REQUEST PAYMENT",
      value: "https://api.qosic.net/QosicBridge/tm/v1/requestpayment",
    },
    {
      key: "URL TOGOCEL FOR GET TRANSACTION STATE",
      value: "https://api.qosic.net/QosicBridge/tm/v1/gettransactionstatus",
    },
    {
      key: "URL TOGOCEL FOR DEPOSIT ",
      value: "https://api.qosic.net/QosicBridge/tm/v1/deposit",
    },
    // {
    //   key: "URL FOR REFUND",
    //   value:
    //   newCountry === "BENIN"
    //       ? "Https://api.qosic.net/Qosbridge/user/refund"
    //       : "Https://api.qosic.net/Qosbridge/user/requestpaymentmv",
    // },
    {
      key: "URL MOOVTG FOR REQUEST PAYMENT",
      value: "https://api.qosic.net/QosicBridge/tg/v1/requestpayment",
    },
    {
      key: "URL MOOVTG FOR GET TRANSACTION STATE",
      value: "https://api.qosic.net/QosicBridge/tg/v1/gettransactionstatus",
    },
    {
      key: "URL MOOVTG FOR DEPOSIT ",
      value: "https://api.qosic.net/QosicBridge/tg/v1/deposit",
    },
  ];

  const ListCi = [
    {
      key: "URL IVORY_COAST FOR REQUEST PAYMENT",
      value: "https://api.qosic.net/QosicBridge/ci/v1/requestpayment",
    },
    {
      key: "URL IVORY_COAST FOR GET TRANSACTION STATE",
      value: "https://api.qosic.net/QosicBridge/ci/v1/gettransactionstatus",
    },
    {
      key: "URL IVORY_COAST FOR DEPOSIT ",
      value: "https://api.qosic.net/QosicBridge/ci/v1/deposit",
    },
    // {
    //   key: "URL FOR REFUND",
    //   value:
    //   newCountry === "BENIN"
    //       ? "Https://api.qosic.net/Qosbridge/user/refund"
    //       : "Https://api.qosic.net/Qosbridge/user/requestpaymentmv",
    // },
    // {
    //   key: "URL FOR GET TRANSACTION STATE",
    //   value: "https://api.qosic.net/QosicBridge/tg/v1/gettransactionstatus",
    // },
  ];



  const Data = useMemo(
    () =>
      newCountry === "TOGO" || newCountry === "🇹🇬"
        ? ListTg
        : newCountry === "IVORY_COST" || newCountry === "🇨🇮"
        ? ListCi
        : List,
    [newCountry]
  );

  function generate() {
    generateCredentials(Operator).then(
      (res) => res && setUpdated((prev) => !prev)
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isUrlValid) return; // Prevent submission if URL is not valid

    setLoading(true);

    const datas = {
      callbackUrl: url,
      clientId: credentials,
    };

    try {
      const { data: status } = await CallbackUrl(datas)

      // console.log("status",status);

      if (status.responseMessage === 'AJOUT EFFECTUE AVEC SUCCES') {
        Swal.fire({
          position: 'top-end',
          title: 'Success !',
          text: status.responseMessage,
          icon: 'success',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
          toast: true,
        });
      
        setTimeout(() => {
          // handleCclose and inf functions should be defined elsewhere in your code
          AllcallBack();
          inf();
          handleCclose();
        }, 2000);
      } else {
        Swal.fire({
          position: 'top-end',
          title: 'Warning',
          text: status.responseMessage,
          icon: 'warning',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
          toast: true,
        });
        setTimeout(() => {
          // handleCclose and GetAllMyLisOfIpWhiteList functions should be defined elsewhere in your code
         
          inf();
        }, 3000);
      }

    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 5500,
      });
    } finally {
      setLoading(false);
    }
  };
  
  function handleEditToast  () {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  
    Toast.fire({
      icon: "error",
      title: `${Infos?.msg?.enab}`,
    });
  }

  const allResponseMessages = allCallback
  .map((response) => response.responseMessage)
  
  const result = allResponseMessages?.every(subArray => subArray.length === 1 && subArray[0] === '') ? (<p className="text-qosTab mt-4 text-center">
    No data to display
  </p>) : '';
  // console.log("allResponseMessages",typeof allResponseMessages)

  // console.log("allResponseMessages",allResponseMessages)

  return (
    <div className="mt-1 ">
      <div className="px-2 my-2  ">
        <div className="col-span-6 mb-1  flex justify-end lg:px-20 ">
          <div>
            {loading ? (
              <select
                id="country"
                name="country"
                autoComplete="country"
                className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
              >
                <option className="text-white m-1">Country</option>
              </select>
            ) : (
              <select
                onChange={(e) => ListOfCredential(e)}
                id="country"
                name="country"
                autoComplete="country"
                className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
              >
                {/* <option value="" disabled selected hidden>
              Country
              </option> */}
                {info?.data?.datas?.items?.map((item) => (
                  <option key={item.index}>
                    {item === "TOGO"
                      ? "🇹🇬"
                      : item === "BENIN"
                      ? "🇧🇯"
                      : item === "IVORY_COST" || item === "IVORY_COAST"
                      ? "🇨🇮"
                      : ""}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="col-span-6 ">
          {/* {  loadingCredential ? "tokodd" : listCredential.data.datas.items[0].username }  */}
        </div>

        <div className="flex  lg:px-20  row justify-start">
          <div className="bg-white overflow-x-auto hideScroll sm:rounded-lg p-2">
            <table id="mee" className="no-vertical-lines border">
              <thead>
                <tr className="text-center">
                  <th className="px-4 py-3 text-mlg text-qosdark text-opacity-100">
                    {Infos.msg.Client}
                  </th>
                  <th className="px-4 py-3 text-mlg text-qosdark text-opacity-100">
                    {Infos.msg.Operator}
                  </th>
                  <th className="px-4 py-3 text-mlg text-qosdark text-opacity-100">
                    {Infos.msg.Username}
                  </th>
                  <th className="px-4 text-center py-3 text-mlg text-qosdark text-opacity-100">
                    {Infos.msg.Actions}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loadingCredential
                  ? "loading..."
                  : listCredential?.data?.datas?.items.map((val, key) => {
                      return (
                        <tr className="text-center  bg-white" key={key}>
                          <td className=" bg-white px-4 py-2">
                            {val.client_id}
                          </td>
                          <td className="  bg-white px-4 py-2">
                            {val.operator}
                          </td>
                          <td className=" bg-white px-4 py-2">
                            {val.username}
                          </td>
                          <td className=" bg-white px-4 py-2">
                            <div className="w-full flex justify-center">
                              <button
                                value={val.client_id}
                                onClick={(e) => {
                                  modal.current.classList.remove(
                                    "-translate-y-full"
                                  );
                                  setReg(e.target.value);
                                }}
                                className="bg-qosorange block p-2.5 text-qosgray rounded-sm font-medium"
                              >
                                {Infos.msg.Reset} api password
                              </button>
                            </div>

                            {isOpen && (
                              <section
                                key={key}
                                id="modal"
                                ref={modal}
                                className="absolute transform -translate-y-full duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-screen h-screen left-0"
                                onClick={(e) =>
                                  e.target.id === "modal"
                                    ? modal.current.classList.add(
                                        "-translate-y-full"
                                      )
                                    : ""
                                }
                              >
                                <div className="max-w-screen-sm duration-500 bg-white rounded-md relative p-4 my-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                  <div className="">
                                    <div className="p-1">
                                      <img
                                        src={qosLogoBlack}
                                        alt=""
                                        className="h-8 w-fit"
                                      />
                                    </div>
                                    <div className="sm:grid justify-center mt-10 mb-4">
                                      <h4 className="font-medium text-center">
                                        {Infos.msg.Do}
                                      </h4>
                                      <h4 className="font-medium text-center">
                                        {Infos.msg.Api}
                                      </h4>
                                    </div>

                                    <div className="sm:flex">
                                      <button
                                        disabled={isLoading}
                                        value={val.client_id}
                                        onClick={(e) =>
                                          SubscribeToOffer({
                                            service_name: reg,
                                          })
                                        }
                                        className={`w-4/12 h-12 mb-4 flex justify-center items-center rounded-md text-qosgray text-opacity-80 font-bold ${
                                          isLoading
                                            ? "bg-coolGray-300"
                                            : "bg-qosorange"
                                        } btn-sm md:max-w-sm mx-auto mt-4`}
                                      >
                                        {isLoading ? (
                                          <Spinner className="border-qosorange h-4 w-4 mx-1" />
                                        ) : (
                                          `${Infos.msg.Yes}`
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>

            <style jsx>{`
              .no-vertical-lines td,
              .no-vertical-lines th {
                border-left: none !important;
                border-right: none !important;
              }
            `}</style>

            {/* <button>tokooss</button> 
            <button
            
            // onClick={() => setEdit((prev) => !prev)}
            // onClick={(e) => SubscribeToOffer({service_name: val.name, calculation_unit: val.calculation_unit })}
            className="bg-qosdark bg-opacity-70 mt-10 min-w-max w-44 mx-auto block p-2 text-black rounded-lg font-medium"
          >
            BUTTON
          </button> */}
          </div>
        </div>
      </div>

      <div className="bg-white lg:px-20 py-2 my-2 sm:rounded-lg mb-10">
        <Tablecompo dataColumns={credentialsHeader} content={Data} />
      </div>

      <div className="mt-4 bg-white lg:px-20 py-2 my-2 sm:rounded-lg flex justify-end items-center ">
        {encryptStorage.getItem("qosUser")?.isUsing2Fa == false ||
        encryptStorage.getItem("qosUser")?.isUsing2Fa == null ? (
          <button
            onClick={handleOpen1}
            className="flex justify-around bg-qosorange  min-w-max w-44  p-2 text-white rounded-sm font-medium"
          >
            Add callback URL
            <Icon
              icon="ri:add-line"
              height={24}
              style={{ color: "white", fontSize: "20px" }}
            />
          </button>
        ) : (
          <button
            onClick={handleOpen}
            className="flex justify-around bg-qosorange  min-w-max w-44  p-2 text-white rounded-sm font-medium"
          >
            Add callback URL
            <Icon
              icon="ri:add-line"
              height={24}
              style={{ color: "white", fontSize: "20px" }}
            />
          </button>
        )}
      </div>

      <div className="bg-white lg:px-20 py-2 my-2 sm:rounded-lg">
        <Table striped hover bordered id="mee" className="no-vertical-lines ">
          <thead>
            <tr className="text-center bg-qosgraylink text-qosTab">
              <th className="px-4 py-3 text-mlg  text-opacity-100">
                {Infos.msg.Operator}
              </th>

              <th className="px-4 py-3 text-mlg  text-opacity-100">
                {Infos.msg.url}
              </th>
              <th className="px-4 text-center py-3 text-mlg  text-opacity-100">
                {Infos.msg.Actions}
              </th>
            </tr>
          </thead>
          <tbody className="mt-10 md:pr-10 md:pl-4">
            {allCallback?.map((item, index) =>
              item.responseMessage.map((ip, idx) => (
                <tr key={index + "_" + idx} className="ml-10">
                  {ip === " " || ip===""? "": (<>
                  
                    <td className="flex gap-4">
                    {/* <Icon
                      icon="carbon:url"
                      height={30}
                      style={{ color: "orange" }}
                    /> */}
                    <p className="pl-5 pt-1">{item.operator}</p>
                  </td>
                  <td className="justify-end">
                    <p className="pt-1">{ip}</p>
                  </td>
                  <td className="justify-end">
                    {encryptStorage.getItem("qosUser")?.isUsing2Fa == false ||
                    encryptStorage.getItem("qosUser")?.isUsing2Fa == null ? (
                      <p
                        onClick={handleOpen1}
                        className="pt-1 text-center text-qosorange cursor-pointer"
                      >
                        Edit
                      </p>
                    ) : (
                      <p
                        onClick={handleOpen}
                        className="pt-1 text-center text-qosorange cursor-pointer"
                      >
                        Edit
                      </p>
                    )}
                  </td>
                  <td className="justify-end">
                      <Icon
                        className="cursor-pointer"
                        icon="material-symbols:delete-outline"
                        height={24}
                        onClick={() => handleCalllist(item.clientID, ip)}
                        style={{ color: "#FF5403", fontSize: "20px" }}
                      />
                    </td>
                  </>) }
               
                </tr>
              ))
            )}
          </tbody>
        </Table>
       {result}
      </div>
      <style jsx>{`
        .no-vertical-lines td,
        .no-vertical-lines th {
          border-left: none !important;
          border-right: none !important;
        }
      `}</style>
      <Modal
        show={show1}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Title
          className="flex justify-end items-end align-end pr-3"
          onClick={handleCclose}
        >
          <button style={{ marginRight: 15, marginTop: 10 }}>X</button>
        </Modal.Title>
        <Modal.Body>
          <div className="bg-white  py-2  sm:rounded-lg">
            <div>
              <div className="w-full   p-2">
                <div className="max-w-full xl relative overflow-hidden  mt-2 mx-auto my-2 sm:rounded-lg ">
                  <div className="flex gap-4 justify-end sm:rounded-lg ">
                    <div>
                      {doloading ? (
                        <span className="text-qosText ">Loading...</span>
                      ) : (
                        <select
                          id="operators"
                          onChange={handleChangess}
                          name="operators"
                          autoComplete="operators"
                          className="h-18  bg-white bg-opacity-70 text-black text-mmd px-4 md:w-40 min-w-max "
                        >
                          <option
                            value=""
                            selected
                            hidden
                            className="text-black"
                          >
                            Select operator
                          </option>
                          {/* <option value=""  selected hidden >{listOperators[0]}</option> */}
                          {listOperators?.data?.datas?.items?.map((item) => (
                            <option key={item.index}>{item}</option>
                          ))}
                        </select>
                      )}
                    </div>

                    <div>
                      <select
                        onChange={(e) => ListOfOperator(e)}
                        className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd "
                      >
                        {doloading
                          ? "loading.."
                          : Infocountries?.data?.datas?.items?.map((item) => {
                              // console.log("Infocountries Infocountries", item);
                              return (
                                <option
                                  className="text-qosorange m-1 w-10"
                                  key={item.index}
                                >
                                  {item === "TOGO"
                                    ? "🇹🇬"
                                    : item === "BENIN"
                                    ? "🇧🇯"
                                    : item === "IVORY_COST" ||
                                      item === "IVORY_COAST"
                                    ? "🇨🇮"
                                    : ""}
                                </option>
                              );
                            })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" justify-between mt-4  items-center">
              <div className="py-2">
                <p className="text-gray">callback URL</p>
              </div>

              <div
                // style={styles.input}
                className="w-full "
              >
                <input
                  style={styles.input}
                  onChange={handleInputChange}
                  className="flex w-full  bg-white border my-1 border-qosgraye gap-1 rounded-lg justify-around"
                  value={url}
                  name="url"
                  type="url"
                  placeholder="https://example.com"
                  id="url"
                  pattern="https://.*"
                  required
                />
              </div>
            </div>
            {!isUrlValid && (
              <div className="text-red text-center mt-4">
                URL is not in the correct format. Please enter a valid URL
                starting with "https://".
              </div>
            )}

            {isUrlValid ? (
              <div className="my-8 mb-20">
                <button
                  // disabled={isLoading}
                  onClick={handleSubmit}
                  style={styles.button}
                  className={` w-full xl:md:w-1/5 lg:md:w-1/5  flex justify-center items-center  text-white text-opacity-80  ${
                    isLoading ? "bg-coolGray-300" : "bg-qosorange"
                  }  p-2.5 btn-sm mx-auto `}
                >
                  {isLoading ? (
                    <Spinner className="border-qosorange h-4 w-4 mx-1" />
                  ) : (
                    <div className="flex gap-2 px-10 justify-center align-center">
                      <span>Save</span>
                      {/* <span className="fi-rr-arrow-right mt-1"></span> */}
                    </div>
                  )}
                </button>
              </div>
            ) : (
              <div className="my-8 mb-20">
                <button
                  disabled={true}
                  //  onClick={handleSubmit}
                  style={styles.button}
                  className={` w-full xl:md:w-1/5 lg:md:w-1/5  flex justify-center items-center  text-white text-opacity-80  ${
                    isLoading ? "bg-coolGray-300" : "bg-qosgray"
                  }  p-2.5 btn-sm mx-auto `}
                >
                  <div className="flex gap-2 px-10 justify-center align-center">
                    <span>URL is not in the correct</span>
                  </div>
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Title
          className="flex justify-end items-end align-end pr-3"
          onClick={handleCclose3}
        >
          <button style={{ marginRight: 15, marginTop: 10 }}>X</button>
        </Modal.Title>
        <Modal.Body>
          <div>
            <div className="w-full   p-2">
              <div className="flex justify-center mt-4 ">
                <span
                  className="text-dark text-center "
                  style={{ fontSize: 18 }}
                >
                  {Info.msg.deleteCall}
                </span>
              </div>

              <div className="w-full flex  mt-1 justify-center items-center p-1">
                <div className="w-full p-1 mt-4 mb-2 flex gap-2">
                  <button
                    // disabled={isLoading}
                    // onClick={handleCclose2}
                    style={styles.button}
                    className={` w-full xl:w-full lg:w-full rounded-md bg-qosorange flex justify-center items-center  text-white text-opacity-80   p-3 btn-sm mx-auto mt-14`}
                  >
                    {Info.msg.no}
                  </button>
                  <button
                    disabled={loading}
                    onClick={handleDeleteCallBack}
                    style={styles.button}
                    className={` w-full xl:w-full lg:w-full rounded-md  flex justify-center items-center  text-white text-opacity-80  ${
                      loading ? "bg-coolGray-300" : "bg-qosred"
                    }  p-3 btn-sm mx-auto mt-14`}
                  >
                    {loading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      <div className="flex gap-2  justify-center align-center">
                        <span>{Info.msg.yes}</span>
                        {/* <span className="fi-rr-arrow-right mt-1"></span> */}
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ModalError show={show} handleClose={handleClose} handleCclose1={handleCclose1}/>
   
    </div>
  );
}

// const styles = {
//   backgroundColor: "#0e730f",
//   borderRadius: 3,
//   padding: 2,
//   marginLeft: 10,
//   width: 60,
//   borderColor: "#0e730f",
//   alignContent: "center",
//   borderWidth: 1,
//   color: "white",
//   textAlign: "center",
//   alignItems: "center",
//   justifyContent: "center",
//   fontSize: "12px",

// };

const styles = {
  input:{
    // width:680,
    // height:"42px",
    borderRadius:4,
  },
  button:{
    // width:680,
    // height:"20",
    borderRadius:5,
  }
}


