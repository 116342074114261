import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
// import axios from "axios";
import 
// useIsMobile,
 { useFormData } from "../../../common/utils/hooks";
import PhoneInput from "react-phone-input-2";
import { media } from "../../../common/libs/media";
import Form from 'react-bootstrap/Form';
import {PostData } from "../../../common/services/httpServices";
import { Endpoints } from "../../../common/utils/endpoints";
import Wrapper from "../../../components/sectionWrapper.component";
import Messages from "./translations/index.json";
import InputsText from "../../../translations/input.json";
import buttonText from "../../../translations/button.json";
import { useLangContext } from "../../../components/translateContext";
import { encryptStorage } from "../../../common/services/encryptData";
import { Link } from "react-router-dom";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Spinner } from "../../../components/spinner.component";
import { CreateUser } from "../../../common/auth";
import { CreateNew } from "../../../common/auth";
import { trackEvent } from "../../../common/utils/analytics";
import Swal from "sweetalert2";
import showPwdImg from './showPwdImg.svg';
import hidePwdImg from './hidePwdImg.svg';

export default function JoinTeamPage() {
  const [isLoading, setLoading] = useState(false);
  const { Lang } = useLangContext();
  const [data, handleInput] = useFormData();
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [resize, setResize] = useState(false);
  const Infos = { msg: Messages[Lang], input: InputsText[Lang] };
  const btnMsg = buttonText[Lang];
  const history = useHistory();
  const [countr, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  console.log("data", data.companyname);
  // console.log("country", countr);


  // const country = { country: "COTE D'IVOIRE"}


  // const  data = { ...country, data}


  useEffect(() => {
    if (encryptStorage.getItem("qosToken")) {
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screenSize = window.innerWidth;
      setResize(screenSize);
    });
    return () => {
      window.removeEventListener("resize", () => {
        let screenSize = window.innerWidth;
      });
    };
  }, []);

  const validateCompanyName = (name) => {
    const regex = /^[a-zA-Z0-9 ]+$/;
    return regex.test(name);
  };

  const handleCompanyNameChange = (e) => {
    const { value } = e.target;
    handleInput(e);
    setIsCompanyNameValid(validateCompanyName(value));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    trackEvent('Signup', 'Signup dashboard', `Name: ${data.lastname}, LastName: ${data.firstname}, Email: ${data.email}`);
    try {
      // console.log("user Data : ", data)
      const { data: user, status } = await CreateNew(data);
      // console.log("un user status : ", status)
      // console.log("un user authData : ", user)
      // console.log("un user response : ", user.responseMessage)
      // console.log("un user authSatus : ", status)
      
      if (user.responseMessage === "A mail has been sent to your address. Please check" ) {
        // console.log("un usere authUser oks : ", user);
        // console.log("un users authStatus oks : ", status);
          // Swal.fire({
          //   position: 'top-end',
          //   icon: 'success',
          //   title: user.responseMessage,
          //   showConfirmButton: false,
          //   timer: 5500
          // });
          setLoading(false)
          Swal.fire({
              position: 'top-end',
              title: 'success!',
              text:  user.responseMessage,
              icon: 'success',
              showConfirmButton: false,
              timer: 9000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
              toast:true,
            })
        history.push("/AccountCreated", { detail: `${user.datas.email}` });
      }else if( user.responseMessage === "Mail already exist "){
       
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/");
      } else if( user.responseMessage === "Company already exist "){
       
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/"); 
      } else if( user.responseMessage === "Country empty "){
     
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/");
      }else if( user.responseMessage === "Invalid activity description "){
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/");
      } 
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        timer: 5500,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
     
      Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite. Contactez support",
        });
    }
    setLoading(false);
  }



  // useEffect(() => {
  //   let stepForm = document.querySelector("#stepForm");
  //   let formWidth = stepForm.clientWidth;
  //   let CurrentScroll = stepForm.scrollLeft;
  //   let currentPos = 1;

  //   function ControlHandler() {
  //     currentPos = +(CurrentScroll / formWidth);

  //     if (CurrentScroll < formWidth * 2 && this.id === "next") {
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos].classList.toggle("active");
          
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos + 1].classList.add("active");
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos].classList.add("passed");
  //       CurrentScroll += formWidth;
  //       stepForm.scrollTo({ left: CurrentScroll, behavior: "smooth" });
  //     }

  //     if (CurrentScroll > 0 && this.id === "prev") {
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos].classList.toggle("active");
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos - 1].classList.toggle("passed");
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos - 1].classList.add("active");

  //       CurrentScroll -= formWidth;
  //       stepForm.scrollTo({ left: CurrentScroll, behavior: "smooth" });
  //     }

  //     if (CurrentScroll === formWidth * 2) {
  //       setTimeout(() => {
  //         document.querySelector("#next").setAttribute("form", "stepForm");
  //         document
  //           .querySelector("#next")
  //           .firstChild.replaceWith(Infos.msg.registerButton);
  //       });
  //     } else {
  //       document.querySelector("#next").removeAttribute("form");
  //       document
  //         .querySelector("#next")
  //         .firstChild.replaceWith(Infos.msg.nextButton);
  //     }

  //     if (CurrentScroll > 0) {
  //       document.querySelector("#prev").hidden = false;
  //     } else {
  //       document.querySelector("#prev").hidden = true;
  //     }
  //   }
  //   stepForm.querySelectorAll("input").forEach((input) => {
  //     input.readOnly = true;
  //     input.addEventListener("click", function (e) {
  //       this.readOnly = false;
  //     });
  //     input.addEventListener("focus", function (e) {
  //       this.readOnly = false;
  //       stepForm.scrollTo({
  //         left: formWidth * (this.parentElement.parentElement.id - 1),
  //         behavior: "smooth",
  //       });
  //     });
  //   });

  //   document.querySelectorAll(".controls").forEach((buttonControl) => {
  //     buttonControl.addEventListener("click", ControlHandler);
  //   });
  //   return () => {
  //     document.querySelectorAll(".controls").forEach((buttonControl) => {
  //       buttonControl.removeEventListener("click", ControlHandler);
  //     });
  //   };
  // }, [Infos.msg.registerButton, Infos.msg.nextButton]);

// console("stepStatus", Infos.msg.registerButton)
  return (


    <Wrapper className="">
      <div className="bg-white  border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2 ">
        <div className="w-full p-4  flex bg-white justify-center items-center ">
          {Lang === "en" ? (
            <h5 className="text-center md:text-mH1">
             
              {/* {Infos.msg.Title2} */}
              Join Team
            </h5>
          ) : (
            <h5 className="text-center md:text-mH1">
             Join Team
            </h5>
          )}
        </div>
        <form onSubmit={handleSubmit} className="  w-full ">
      

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block"> {Infos.input.firstname.label} </span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="firstname"
              value={data.firstname}
              onChange={handleInput}
              required
              placeholder={Infos.input.firstname.placeholder}
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block"> {Infos.input.lastname.label}</span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="lastname"
              value={data.lastname}
              onChange={handleInput}
              required
              placeholder={Infos.input.lastname.placeholder}
            />
          </div>

    

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block"> {Infos.input.tel.label}</span>
            <PhoneInput
              // style={styles.input}
              inputStyle={{
                color: "black",
                height: 50,
                width: "100px",
                paddingLeft: 70,
              }}
              buttonStyle={{ padding: 8 }}
              country={"bj"}
              placeholder={"229"}
              required
              // onlyCountries={["bj", "tg", "ng", "bf", "bf", "ci", "cm", "ml", "ne", "sn"]}
              // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
              autoFormat={true}
              // containerClass="mt-2"\
              // containerStyle={{margin:'20px', height:50}}
              inputClass="w-full min-w-full h-10"
              inputProps={{ required: true, name: "phone" }}
              disableCountryCode={false}
              countryCodeEditable={false}
              onChange={(phone, country, value) => {
                handleInput({
                  phone: phone,
                  // country: `${country.name}`.toUpperCase(),
                  // value: value,
                  // countr:countr,
                });
              }}
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            {/* <span className="block">{Infos.input.business.label}</span> */}
            <span className="block">
                {/* {Infos.input.email.label}  */}

            Job title
            </span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="email"
              value={data.email}
              onChange={handleInput}
              type="email"
              required
              placeholder="Job title"
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block">{Infos.input.password.label}</span>
      
            <div
              style={styles.input}
              className="flex bg-white border my-1 border-qosgraye gap-1 rounded-lg justify-around "
            >
              <input
                style={styles.input}
                onChange={handleInput}
                className="bg-transparent border-none rounded"
                value={data.password}
                name="password"
                type={isRevealPwd ? "text" : "password"}
                required
                placeholder={Infos.input.password.placehol}
              />

              <img
                height={20}
                width={20}
                className="mx-2 bg-white"
                title={isRevealPwd ? "Hide password" : "Show password"}
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
          </div>
          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block">
                {/* {Infos.input.password.label} */}
            Confirm Password
            </span>
      
            <div
              style={styles.input}
              className="flex bg-white border my-1 border-qosgraye gap-1 rounded-lg justify-around "
            >
              <input
                style={styles.input}
                onChange={handleInput}
                className="bg-transparent border-none rounded"
                value={data.password}
                name="password"
                type={isRevealPwd ? "text" : "password"}
                required
                placeholder="Confirm Password"
              />

              <img
                height={20}
                width={20}
                className="mx-2 bg-white"
                title={isRevealPwd ? "Hide password" : "Show password"}
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block">{Infos.input.dev.title}</span>
            <select
              style={styles.input}
              name="developperKnows"
              onChange={handleInput}
            >
              <option disabled selected hidden>
                {Infos.input.dev.placeholder}{" "}
              </option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </select>
          </div>

       

          {!isCompanyNameValid ? ( <>
            <p className="text-red mx-auto  lg:w-8/12 mt-3 mb-3">{Infos.input.Control}</p>
          </>) :( <button
            disabled={isLoading}
            style={styles.button}
            className={`w-full xl:w-8/12 lg:w-8/12  flex justify-center items-center  text-white text-opacity-80  ${
              isLoading ? "bg-coolGray-300" : "bg-qosorange"
            } p-3 btn-sm  mx-auto mt-14`}
          >
            {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1" />
            ) : (
              <div className="flex gap-2  justify-center align-center">
                <span>
                    {/* {btnMsg.createAccount} */}
                Join Your Team
                </span>
                <span className="fi-rr-arrow-right mt-1"></span>
              </div>
            )}
          </button>
          )}

        </form>
      </div>
    </Wrapper>
  );
}

const styles = {
  input:{
    // width:680,
    // height:"42px",
    height:50,
    borderRadius:4,
  },
  button:{
    // width:680,
    height:"28",
    borderRadius:8,
  }
}

