import React from "react"; // useRef // useMemo , useEffect, useState,
// import DoughnutChart from "../../charts/DoughnutChart";
// import { percent } from "../../common/utils/tools";
// Import utilities
// import { tailwindConfig } from "../../utils/Utils";
import numeral from "numeral";
import { Endpoints } from "../../common/utils/endpoints";
import { FetchData } from "../../common/services/httpServices";

import { useMemo, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useLangContext } from "../../components/translateContext";
import overview from "../../components/translation/overview.json";
import { Spinner } from "../../components/spinner.component";

function DashboardCard06({ allInfo, loading }) {
  // console.log('Pour :',loading)
  // let operateursVariable = []
  // console.log("allInfo22",  loading ? "loading": allInfo.items)

  const { Lang } = useLangContext();
  const Infos = { msg: overview[Lang] };
  const data = allInfo?.items;

   // Initialize selectedCountry as null
   const [selectedCountry, setSelectedCountry] = useState(null);

   // Update selectedCountry when data changes and no country is selected
   useEffect(() => {
     if (data?.length > 0 && !selectedCountry) {
       setSelectedCountry(data[0].country);
     }
   }, [data, selectedCountry]);
 
   // Filter data based on selected country
   const filteredData = useMemo(() => 
     data?.filter(d => d.country === selectedCountry),
     [data, selectedCountry]
   );
 
   // Create array of unique countries
   const countries = useMemo(() =>
     data?.map(d => d.country) || [],
     [data]
   );

   const SkeletonCard = () => (
    <div className="font-semibold px-2 m-1 bg-white border rounded-lg animate-pulse">
      {/* Header */}
      <div className="mt-3 gap-2 flex justify-center items-center px-1 text-center mb-0 border-qosgray">
        <div className="p-1 border rounded-sm bg-qosgray h-8 w-8"></div>
        <div className="h-2 bg-qosgray rounded w-32"></div>
      </div>
      
      {/* Amount section */}
      <div className="flex-inline my-2 text-center justify-center border-b py-4 border-qosgray">
        <div className="h-4 bg-qosgray rounded w-40 mx-auto mb-2"></div>
        <div className="h-2 bg-qosgray rounded w-24 mx-auto"></div>
      </div>
      
      {/* Stats rows */}
      {[1, 2, 3].map((i) => (
        <div key={i} className="flex justify-between px-1 py-2 border-b border-qosgray">
          <div className="h-2 bg-qosgrayrounded w-20"></div>
          <div className="h-2 bg-qosgray rounded w-24"></div>
        </div>
      ))}
    </div>
  );


  const mappedData = useMemo(
    () =>
      filteredData?.map((d) => {
        // console.log("my d", data)

        return (
          <div className="gap-1  w-full min-w-max mx-auto overflow-visible items-center   border-opacity-10 border-qosdark ">
            <div className="grid md:flex min-w-max mx-auto overflow-visible items-center  border-opacity-10 border-qosdark ">
              {/* <h5 className="text-center md:text-dH2 " >{d.country}</h5> */}
              {d.operatorInfoList.map((ie, i) => {
                return (
                  <>
                    {" "}
                    {ie?.operator === "CARD" ? (
                      <div className="gap w-full min-w-max mx-auto overflow-visible items-center   border-opacity-10 border-qosdark ">
                        {/* <div>
         operatuer : {ie.operator}
         amount: {ie.userWalletResponseList.amount}
         totalAmount: {ie.userWalletResponseList.totalAmount}
       </div> */}

                        <div
                          className=" gap w-full min-w-max mx-auto overflow-visible items-center px-2  border-opacity-10 border-qosdark "
                          key={i}
                        >
                          <div className="font-semibold px-2 m-2 bg-white border rounded-lg">
                            {/* <div className="px-1 text-center tracking-tighter text-msm  bg-opacity-70 text-qosorange font-bold p-1 mb-0">
                                {ie.operator}
                              </div> */}
                            <div className=" mt-3 gap-2 flex justify-center  items-center px-1 text-center tracking-tighter text-msm  bg-opacity-70 text-qosorange font-bold p-1 mb-0">
                              <div className="p-1 border rounded-sm border-qosgray ">
                                <Icon
                                  icon="ic:outline-account-balance-wallet"
                                  height={24}
                                  style={{
                                    color: "orange",
                                    fontSize: "20px",
                                  }}
                                />
                              </div>
                              <div>
                                <p>{ie.operator} </p>
                              </div>
                            </div>
                            {/* <div className="flex text-left justify-between align-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                                <span className="text-left text-msm">
                                  {" "}
                                  {Infos.msg.Available}{" "}
                                </span>

                                <span className="text-dST text-center md:text-mH3">
                                  {numeral(
                                    ie.userWalletResponseList.amount
                                  ).format("0,0[.]00")}
                                  <span className="text-center pl-2 text-msm  md:text-msm">
                                    {" "}
                                    XOF
                                  </span>
                                </span>
                              </div> */}
                            <div className="flex text-left mt-2.5 align-center font-bold border-b border-qosdark border-opacity-20 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                              <span className="text-left text-msm">
                                {" "}
                                {/* {Infos.msg.Available}{" "} */}
                              </span>
                            </div>
                            <div className="flex-inline  text-center justify-center align-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter   text-opacity-90">
                              <div className="">
                                <span className="text-dST text-center md:text-dH3">
                                  {numeral(
                                    ie.userWalletResponseList.amount
                                  ).format("0,0[.]00")}
                                  <span className="text-center pl-2 text-msm  md:text-msm">
                                    {" "}
                                    XOF
                                  </span>
                                </span>
                              </div>
                              <div className=" text-center align-center py-1  text-qosdark text-opacity-90">
                                <p className="text-center ">
                                  {" "}
                                  {Infos.msg.Available}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="flex text-left mt-1 justify-between px-1 align-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                              <p className="text-left text-msm">
                                {" "}
                                {Infos.msg.Ledge}{" "}
                              </p>

                              <p className="text-dST text-center md:text-mH3">
                                {/* {numeral(ie.userWalletResponseList.totalAmount).format("0,0[.]00")} */}
                                {numeral(
                                  ie.userWalletResponseList.ledgerAmount
                                ).format("0,0[.]00")}
                                <span className="text-center pl-2 text-msm  md:text-msm">
                                  {" "}
                                  XOF
                                </span>
                              </p>
                            </div>

                            {/* <div className="flex text-left justify-center align-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                  <span className="text-dST text-center md:text-dH3">
                    {numeral(ie.userWalletResponseList.amount).format("0,0[.]00")}
                    <span className="text-center pl-2 text-msm  md:text-msm">
                      {" "}
                      XOF
                    </span>
                  </span>
                </div> */}

                            <div className="flex text-left bg-white justify-between px-1 align-center font-bold  border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                              <p className="text-left text-msm">
                                {" "}
                                {Infos.msg.Value}:{" "}
                              </p>
                              <p className="text-left text-msm">
                                {" "}
                                {ie.transactionStatResponse.inflow.map(
                                  (item, index) => {
                                    if (index === 0) {
                                      return (
                                        <div key={index}>
                                          {numeral(item.amount).format(
                                            "0,0[.]00"
                                          )}{" "}
                                          XOF
                                        </div>
                                      );
                                    }
                                  }
                                )}
                                {/* {numeral(ie.userWalletResponseList.amount).format("0,0[.]00")} XOF */}
                              </p>
                            </div>

                            <div className="flex text-left bg-white justify-between px-1 align-center font-bold border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                              <p className="text-left text-msm">
                                {" "}
                                {Infos.msg.Volume}:{" "}
                              </p>
                              <p
                                className="text-center pl-2 text-msm  md:text-msm"
                                style={{ fontSize: 17 }}
                              >
                                {" "}
                                {/* {numeral(item.coun).format("0,0[.]00")} */}
                                {ie.transactionStatResponse.inflow.map(
                                  (item, index) => {
                                    if (index === 0) {
                                      return (
                                        <div key={index}>
                                          {numeral(item.count).format(
                                            "0,0[.]00"
                                          )}
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className=" w-full min-w-max mx-auto overflow-visible items-center   border-opacity-10 border-qosdark ">
                        {/* <div>
         operatuer : {ie.operator}
         amount: {ie.userWalletResponseList.amount}
         totalAmount: {ie.userWalletResponseList.totalAmount}
       </div> */}

                        <div
                          className=" gap w-full min-w-max mx-auto overflow-visible items-center  border-opacity-10 border-qosdark "
                          key={i}
                        >
                          <div className="font-semibold px-2 m-1 bg-white border rounded-lg">
                            <div className=" mt-3 gap-2 flex justify-center items-center px-1 text-center tracking-tighter text-msm  bg-opacity-70 text-qosorange font-bold p-1 mb-0">
                              <div className="p-1 border rounded-sm border-qosgray ">
                                <Icon
                                  icon="ic:outline-account-balance-wallet"
                                  height={24}
                                  style={{
                                    color: "orange",
                                    fontSize: "20px",
                                  }}
                                />
                              </div>
                              <div>
                                <p>{ie.operator} mobile money</p>
                              </div>
                            </div>
                            <div className="flex text-left align-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                              <span className="text-left text-msm">
                                {" "}
                                {/* {Infos.msg.Available}{" "} */}
                              </span>
                            </div>

                            <div className="flex-inline my-2 text-center justify-center align-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter   text-opacity-90">
                              <div className="">
                                <span className="text-dST text-center md:text-dH3">
                                  {numeral(
                                    ie.userWalletResponseList.amount
                                  ).format("0,0[.]00")}
                                  <span className="text-center pl-2 text-msm  md:text-msm">
                                    {" "}
                                    XOF
                                  </span>
                                </span>
                              </div>
                              <div className=" text-center align-center py-3  text-qosdark text-opacity-90">
                                <p className="text-center ">
                                  {" "}
                                  {Infos.msg.Available}{" "}
                                </p>
                              </div>
                            </div>

                            <div className="flex text-left bg-white justify-between px-1 align-center font-bold  border-qosdark border-opacity-20 py-2 tracking-tighter  text-qosdark text-opacity-90">
                              <p className="text-left text-msm">
                                {" "}
                                {Infos.msg.Value}:{" "}
                              </p>
                              <p className="text-left text-msm">
                                {" "}
                                {ie.transactionStatResponse.inflow.map(
                                  (item, index) => {
                                    if (index === 0) {
                                      return (
                                        <div key={index}>
                                          {numeral(item.amount).format(
                                            "0,0[.]00"
                                          )}{" "}
                                          XOF
                                        </div>
                                      );
                                    }
                                  }
                                )}
                                {/* {numeral(ie.userWalletResponseList.amount).format("0,0[.]00")} XOF */}
                              </p>
                            </div>

                            <div className="flex text-left bg-white justify-between px-1 align-center  border-qosdark border-opacity-20 py-2 tracking-tighter  text-qosdark text-opacity-90">
                              <p className="text-left text-msm">
                                {" "}
                                {Infos.msg.Volume}:{" "}
                              </p>
                              <p
                                className="text-center pl-2   md:text-msm"
                                style={{ fontSize: 17 }}
                              >
                                {" "}
                                {/* {numeral(item.coun).format("0,0[.]00")} */}
                                {ie.transactionStatResponse.inflow.map(
                                  (item, index) => {
                                    if (index === 0) {
                                      return (
                                        <div key={index}>
                                          {numeral(item.count).format(
                                            "0,0[.]00"
                                          )}
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}{" "}
                  </>
                );
              })}
            </div>
          </div>
        );
      }),
    [filteredData]
  );

  return (
    <div>
              {/* Show 3 skeleton cards */}
    {/* {loading ? (
      <div className="grid md:flex gap-4">

        {[1, 2, 3].map((i) => (
          <div key={i} className="w-full min-w-max mx-auto">
            <SkeletonCard />
          </div>
        ))}
      </div>
    ) : ( */}
      <>
        {/* Country selector */}
        <div className="mb-4 grid  justify-end md:mx-4">
          <select 
            className="form-select w-full md:w-auto px-8 py-2 border border-gray-300 rounded-md"
            value={selectedCountry || ''}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            {countries.map((country) => (
              <option key={country} value={country}>
                {country==="BENIN" ? "Bénin" : country==="TOGO" ? "Togo" : country==="NIGERIA" ? "Nigeria" : country}
              </option>
            ))}
          </select>
        </div>

        {/* Existing grid display */}
        <div className="grid md:flex">
          {mappedData}
        </div>
      </>
    {/* )} */}
  </div>
  );
}

export default DashboardCard06;
