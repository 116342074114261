// import { DashTable } from "../components/tables/dashtable.component";
// import { Spinner } from "../components/spinner.component";
// import { Switch } from "../components/switch.component";
// import { SelectionState } from "draft-js";
import { useState, useMemo, useEffect } from "react";
import { FetchData, PostData } from "../common/services/httpServices";
import { encryptStorage } from "../common/services/encryptData";
import { Generate } from "../common/auth";

import { useFormData } from "../common/utils/hooks";
import axios from "axios";
import { Endpoints } from "../common/utils/endpoints";

import { isNull } from "lodash";
import { matchSorter } from "match-sorter";

import { makeCsv, getTableDataForExport } from "../common/utils/table";
import DatePicker from "react-flatpickr";
import {
  useTable,
  // useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import GlobalFilter from "../components/GlobalFilter";
import { useLangContext } from "../components/translateContext";
import transaction from "../components/translation/transaction.json";
import transfer from "../components/translation/transfer.json";
import settings from "../components/translation/settings.json";
import Wrapper from "../components/sectionWrapper.component";
import { Spinner } from "../components/spinner.component";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { Icon } from "@iconify/react";
import { media } from "../common/libs/media";
import "./table.css";
import { Withdraw } from "./transfer";
import ModalError from "../components/header.component";


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function PayoutStatements() {
  const [state, setstate] = useState(0);
  const { Lang } = useLangContext();
  const Infos = { msg: transaction[Lang] };
  const Infose = { msg: transfer[Lang] };
  // const [isLoading, setLoading] = useState(false);
  // const btnMsg = Button;

  return (
    <section className="p-4 ">
      {/* <div className="mt-14 mb-5 flex text-left gap-1">
        <p className=" " style={{ fontSize: 20, color: "#45464E" }}>
        {Infose.msg.Payout}
        </p>
      </div> */}
      <div className="flex-column text-left gap-2">
        <h4 style={{ color: "#45464E" }} className="pt-4 ">
        {Infose.msg.Payout}
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
        {Infose.msg.Request}
        </span>
      </div>

    
      <div>
        <section>
          {state === 0 && <Statement />}
      
        </section>
      </div>
    </section>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

export function Statement() {
  const { qosLogoBlack } = media.Logos.qos;
  const [Operator, setOperator] = useState("MTN");

  // const [myAmount, setMyAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [failed, setFailed] = useState(false);
  const { Lang } = useLangContext();
  const Infos = { msg: transaction[Lang] };
  const Infoss = { msg: settings[Lang] };

  // const [data, handleInput] = useFormData();
  // const [info, setInfo] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [Infocountries, setInfocountries] = useState({});
  const [doloading, setDoLoading] = useState(true);
  const [listOperators, setlistOperators] = useState({});
  const [chosenPays, setchosenPays] = useState("");

  const [network, setNetwork] = useState("");

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      // console.log("mon pays", res);
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res?.data?.datas?.items[0] } });
    });
  };

  // const [ Data, setData] = useState([]);
  useEffect(() => {
    countries();
  }, []);

  // const retro = doloading?""

  // console.log("mon pays Infocountries",Infocountries)

  function ListOfOperator(pays) {
    // console.log('yoo')
    // console.log("pays",pays)
    // console.log("pays.target.value",pays.target.value)

    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;
    // console.log("lopetateur", lopetateur)

    if (pays.target) {
      FetchData(
        // Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
        Endpoints.Subscriptions.getOperators + "?country=" + lopetateur
      ).then((res) => {
        // setchosenPays(pays.target.value);
        setchosenPays(lopetateur);
        setlistOperators(res);
      });
    }
  }

  async function handleChange(event) {
    // console.log("voila toi",event.target.value);
    setNetwork(event.target.value);
  }

  const [RequestType, setRequestType] = useState(0);
  const [Data, setData] = useState([]);
  const [DateRange, setDateRange] = useState(null);
  // const [Loading, setLoading] = useState(false);
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  let value =
    RequestType === 2
      ? "MAKEDEPOSIT"
      : "MAKEDEPOSIT";

  let operateur = network;
  // Endpoints.User.getAvailableAmount+'?country='+chosenPays+'&operator='+operateur.target.value,

  useEffect(() => {
    setLoading(true);

    if (operateur != "") {
     
        // console.log("=== req 2 :"+RequestType.toString())
        axios
          .get(
            Endpoints?.Transactions?.getUserSuccessfullDeposit +
              "?operator=" +
              operateur
            // {
            //   params: {
            //     operator: Operator,
            //   },
            // }
          )
          .then((res) => {
            setData(res?.data?.datas?.items?.filter((trx) => !isNull(trx)));
            setLoading(false);
          });

    }
  }, [network, RequestType]);

  useEffect(() => {
    setLoading(true);
    if (operateur !== "") {
      if (DateRange) {
        axios
          .get(
            Endpoints?.Transactions?.getUsertransactionsByClientAndByPeriod +
              "?begin=" +
              DateRange.begin +
              "&country=" +
              chosenPays +
              "&end=" +
              DateRange.end +
              "&operator=" +
              operateur +
              "&type=" +
              value
            // {
            //   params: {
            //     // clientId: getUser(`ID${Operator}`),
            //     begin: DateRange.begin,
            //     end: DateRange.end,
            //   },
            // }
          )
          .then((res) => {
            // console.log("mes looooo", res)
            setData(res?.data?.datas?.items?.filter((trx) => !isNull(trx)));
            // console.log("mes looooo DEDE", setData)
            setLoading(false);
          });
      }
    }
  }, [DateRange]);

  const columns = useMemo(
    () => [
      {
        name: `${Infos.msg.Transac}`,
        accessor: "momtransaction_id",
      },
      {
        name: `${Infos.msg.Reference}`,
        accessor: "clientrefno",
      },

      {
        name: `${Infos.msg.Amount}`,
        accessor: "amount",
      },
      {
        name: `${Infos.msg.Date}`,
        accessor: "trxndate",
        Cell: ({ value }) => {
          return format(new Date(value), "dd-MM-yyyy");
        },
      },
      {
        name: `${Infos.msg.Phone}`,
        accessor: "sendersmsisdn",
      },
      {
        name: `${Infos.msg.Operation}`,
        accessor: "operationtype",
      },
      {
        name: `${Infos.msg.Message}`,
        accessor: "responsemessage",
      },

      // {
      //   name: `${Infos.msg.Time}`,
      //   accessor: "responsetime",
      //   Cell: ({ value }) => {
      //     return format(new Date(value), "H:mm  ");
      //   },
      // },

      // {
      //   name: `${Infos.msg.Client}`,
      //   accessor: "clientid",
      // },
      // {
      //   name: `${Infos.msg.Reference}`,
      //   accessor: "clientrefno",
      // },

      // {
      //   name: "PROCESSING NUMBER",
      //   accessor: "processingnumber",
      // },
    ],
    []
  );
  const data = useMemo(() => Data, [Operator, Data, RequestType]);
  // console.log("nouvele donnee a check" , data)

  const filteredData = useMemo(() => {
    return data.filter((item) => item.operationtype === "PAYOUT" || item.operationtype === "PAYOUTMV");
  }, [data, Operator, RequestType]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    // visibleColumns,
    // preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data:filteredData ,
      initialState: { pageIndex: 0, pageSize: 20 },
      filterTypes,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );


  const handleOpen = () => {
    setShow1(true);
    setIsActive(!isActive);
  };
  const handleOpen1 = () => {
    setShow(true);
    // setIsActive(!isActive);
  };
  const handleCclose = () => {
    setIsActive(!isActive);
    setShow1(false);
  };

  const handleClose = () => {
    // setIsActive(!isActive);
    setShow(false);
  };


  const handleCclose1 = () => {
    // setIsActive(!isActive);
    setShow(false);
  };



  function handleEditToast  () {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  
    Toast.fire({
      icon: "error",
      title: `${Infoss.msg.enab}`,
    });
  }

  return (
    <section>
      {/*   <div className="mb-5 mt-5 max-w-screen-xl mx-auto">
        <Switch setOperator={setOperator} />
      </div> */}
      <div className="w-full mt-10 ">
        <header className="flex flex-wrap p-2 items-center justify-center lg:justify-between py-2">
          <div className=" grid grid-cols-1  md:flex  md:gap-4 justify-start">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            {/* <div>
              <select
                value={RequestType}
                style={styles.input}
                onChange={(e) => setRequestType(e.target.value * 1)}
                className="h-8 text-mmd text-qosText px-4 md:w-40 min-w-max"
              >
                <option selected value="2">
                  Payout
                </option>
                {network === "CARD" ? "" : <option value="2">Payout</option>}
              </select>
            </div> */}

            {/* <div className="mx-2">
              <DatePicker
                style={styles.input}
                className="w-full text-msm font-semibold"
                onChange={(val, value) => {
                  if (val.length > 1) {
                    setDateRange({
                      begin: format(val[0], "Y-MM-dd"),
                      end: format(val[1], "Y-MM-dd"),
                    });
                  }
                }}
                // static: true, for  option
                options={{
                  mode: "range",
                }}
                placeholder="Choose the date range"
              />
            </div> */}
          </div>

          <div className="flex gap-4 justify-end">
            {encryptStorage.getItem("qosUser")?.isUsing2Fa == false ||
            encryptStorage.getItem("qosUser")?.isUsing2Fa == null ? (
              // <button
              //   onClick={handleEditToast}
              //   style={styles.input}
              //   className="bg-qosred  min-w-max w-44 block  text-white rounded-sm font-bold"
              // >
              //   {Infoss.msg.enab}
              // </button>
              <button
              onClick={handleOpen1}
              style={styles.input}
              className=" bg-qosorange  min-w-max w-44 block  text-white rounded-sm font-medium"
            >
              {Infos.msg.payout}
            </button>
            ) : (
              <div className="mt-1">
                <button
                  onClick={handleOpen}
                  style={styles.input}
                  className=" bg-qosorange  min-w-max w-44 block  text-white rounded-sm font-medium"
                >
                  {Infos.msg.payout}
                </button>
              </div>
            )}
          </div>

          {/* <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        /> */}
        </header>
      </div>

      {/* <div className="bg-white max-w-screen-xl my-2 p-2 mx-auto overflow-hidden md:flex justify-between items-center p-4 sm:rounded-lg ">
        <h3 className="md:text-mH2">Generate statements</h3>
        <form>
          <div className="flex items-center m-0 my-2 p-2">
            <select className="w-24 h-10 m-0 my-2 p-2">
              <option value="MTN">MTN</option>
              <option value="MOOV">MOOV</option>
            </select>
            <button className="bg-qosblue bg-opacity-70 ml-4 min-w-max my-2 p-2 text-qosgray rounded-lg">
              Send
            </button>
             {chosenPays}
   
          </div>
        </form>
      </div> */}

      <div className="max-w-full xl relative overflow-hidden mt-5 bg-white mx-auto my-2 sm:rounded-lg ">
        {/* <DashTable   /> */}
        <div className="border p-4">
          <div className="max-w-full xl relative overflow-hidden  mt-9 mx-auto my-2 sm:rounded-lg ">
            <div className="flex gap-4 justify-end sm:rounded-lg ">
              <div>
                {doloading ? (
                  <span className="text-qosText ">Loading...</span>
                ) : (
                  <select
                    id="operators"
                    onChange={handleChange}
                    name="operators"
                    autoComplete="operators"
                    className="h-18  bg-white bg-opacity-70 text-black text-mmd px-4 md:w-40 min-w-max "
                  >
                    <option value="" selected hidden className="text-black">
                      {Infos.msg.selectOp}
                    </option>
                    {/* <option value=""  selected hidden >{listOperators[0]}</option> */}
                    {listOperators?.data?.datas?.items?.map((item) => (
                      <option key={item.index}>
                        {item === "CARD" ? "" : item}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div>
                {/* px-4 md:w-40 min-w-max */}

                <select
                  onChange={(e) => ListOfOperator(e)}
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd "
                >
                  {/* <option value="" disabled selected hidden className="text-black">
                BENIN
              </option> */}
                  {doloading
                    ? "loading.."
                    : Infocountries?.data?.datas?.items?.map((item) => {
                        // console.log("Infocountries Infocountries", item);
                        return (
                          <option
                            className="text-qosorange m-1 w-10"
                            key={item.index}
                          >
                            {item === "TOGO"
                              ? "🇹🇬"
                              : item === "BENIN"
                              ? "🇧🇯"
                              : item === "IVORY_COST" || item === "IVORY_COAST"
                              ? "🇨🇮"
                              : ""}
                          </option>
                        );
                      })}
                </select>
              </div>
            </div>
          </div>
          {/* <header className="flex flex-wrap p-2 items-center justify-center lg:justify-around py-2">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div>
              <select
                value={RequestType}
                style={styles.input}

                onChange={(e) => setRequestType(e.target.value * 1)}
                className="h-8 text-mmd text-qosText px-4 md:w-40 min-w-max"
              >
                <option selected value="0">
                  Request payment
                </option>
                {network === "MTN" && <option value="1">Refund</option>}
                {network === "CARD" ? "" : <option value="2">Deposit</option>}
              </select>
            </div>
            <div className="mx-2">
              <DatePicker
               style={styles.input}
                // style={{ height: "32px", width: "210px" }}
                className="w-full text-msm font-semibold"
                onChange={(val, value) => {
                  if (val.length > 1) {
                    setDateRange({
                      begin: format(val[0], "Y-MM-dd"),
                      end: format(val[1], "Y-MM-dd"),
                    });
                  }
                }}
                // static: true, for  option
                options={{
                  mode: "range",
                }}
                placeholder="Choose the date range"
              />
            </div>

            

            <button
              type="button"
              style={styles.input}
              className="text-mST h-22 p-2 bg-qosorange  rounded-sm text-white mx-2"
              onClick={() =>
                makeCsv(
                  getTableDataForExport(data, columns),
                  `transactions.csv`
                )
              }
            >
              Export to CSV
            </button>
          </header> */}
          <div className="overflow-x-auto hideScroll border ">
            {/*  {Loading && (
          <div className="h-full w-full grid absolute bg-qosblue bg-opacity-10 items-center justify-center">
            <Spinner className="border-qosblue h-8 w-8 mx-1 -mt-20" />
          </div>
        )} */}

            <table id="customers" {...getTableProps()}>
              <thead className="font-extrabold ">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        style={{ fontSize: 18 }}
                        scope="col"
                        className="px-4 py-4 relative text-msm text-qosdark text-opacity-100  tracking-tighter leading-3 truncate text-center"
                        {...column.getHeaderProps()}
                      >
                        {column.render("name")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-qosgraye"
              >
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-6 py-4 whitespace-nowrap"
                            {...cell.getCellProps()}
                          >
                            <div className="text-msm text-center font-medium text-qosdark truncate md:px-4">
                              {cell.render("Cell")}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center md:justify-end bg-coolGray-50 py-2">
            <div className="mx-2 ">
              <select
                defaultValue={pageSize}
                className="h-8 text-mmd text-qosText px-1 md:px-4 md:w-40 min-w-max"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex justify-around max-w-max items-center mt-1 mr-2 h-8">
              <button
                className={`${
                  !canPreviousPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300 delay-200`}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="fi fi-rr-angle-double-small-left"></span>
              </button>{" "}
              <button
                className={`${
                  !canPreviousPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="fi fi-rr-angle-small-left"></span>
              </button>
              <span className="min-w-max mx-4">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <button
                className={`${
                  !canNextPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300`}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <span className="fi fi-rr-angle-small-right"></span>
              </button>{" "}
              <button
                className={`${
                  !canNextPage
                    ? "bg-coolGray-100 text-qosdark border border-qosdark border-opacity-50"
                    : "bg-qosorange text-qosgray"
                } h-8 bg-opacity-80 p-1 px-2 text-msm font-semibold md:textm-mlg text-opacity-70 rounded flex items-end mx-1 transition-all duration-300 delay-200`}
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="fi fi-rr-angle-double-small-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show1}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>
        {/* <Modal.Title
          className="flex justify-end items-end align-end pr-3 bg-white"
          onClick={handleCclose}
        >
          <button style={{ marginRight: 15, marginTop: 10 }}>X</button>
        </Modal.Title> */}
        <Modal.Body>
          <Withdraw />
        </Modal.Body>
      </Modal>

      <ModalError show={show} handleClose={handleClose} handleCclose1={handleCclose1} />
      
    </section>
  );
}



const styles = {
  input: {
    // width:680,
    // height:"42px",
    height: 50,
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
