import React, { useEffect, useMemo, useState, useRef } from "react";
import Popover from "react-bootstrap/Popover";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Table, Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import "./react-tagsinput.css";
import { encryptStorage } from "../../common/services/encryptData";
import { useLangContext } from "../translateContext";
import list from "../translation/whitelist.json";

import {
  FetchData,
  PutData,
  PostData,
} from "../../common/services/httpServices";
import { useTable, useRowSelect } from "react-table";
import { Endpoints } from "../../common/utils/endpoints";
import { useFormData } from "../../common/utils/hooks";
import { AddwhitelisteApi, DeleteApi } from "../../common/auth";
import { Icon } from "@iconify/react";

import { Spinner } from "../spinner.component";
import Swal from "sweetalert2";
// import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DataTable from "react-data-table-component";
import ModalError from "../header.component";

export function WhitelistIp({ onDelete }) {
  const { Lang } = useLangContext();
  const Infos = { msg: list[Lang] };
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [tag, setTag] = useState([]);
  const [credentials, setCredentials] = useState("");
  const [data, setData] = useState("");
  const [deleteIp, setDeleteIp] = useState({});
  const [tableD, setTableD] = useState([]);
  const [tableData, setTableData] = useState([
    { id: 1, name: "190.168.211.10", selected: false },
    { id: 2, name: "190.168.191.10", selected: false },
    { id: 3, name: "190.168.49.120", selected: false },
    { id: 4, name: "190.168.110.10", selected: false },
  ]);

  const [Infocountries, setInfocountries] = useState({});
  const [doloading, setDoLoading] = useState(true);
  const [listOperators, setlistOperators] = useState({});
  const [chosenPays, setchosenPays] = useState("");
  const [network, setNetwork] = useState("");
  const [allIpLists, setAllIpLists] = useState([]);

  let MyCountry = encryptStorage.getItem("qosUser");

  useEffect(() => {
    countries();
    GetAllMyLisOfIpWhiteList();
    inf();
  }, []);

  const MyQosKey = credentials;

  const inf = () => {
    FetchData(Endpoints.Credentials.get + "?country=" + MyCountry.country).then(
      (res) => {}
    );
  };

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res?.data?.datas?.items[0] } });
    });
  };

  function ListOfCredential(MyQosKey) {
    if (MyQosKey) {
      PutData(
        Endpoints.UsersProfile.whitelisteApiAll + "?clientId=" + `${MyQosKey}`
      ).then((res) => {
        setTableD(res?.data?.responseMessage);
      });
    }
  }

  function handleChangess(event) {
    setNetwork(event.target.value);
    // setNewval(event.target.value);
    FetchData(Endpoints.Credentials.get + "?country=" + chosenPays).then(
      (res) => {
        // console.log("response contry",res)
        // setCredentials(res?.data?.datas?.items[2]?.client_id);
        if (res.data.datas.items.length !== 0) {
          // console.log("voila toi nous maj",event.target.value);
          for (let x in res.data.datas.items) {
            let elem = res.data.datas.items;
            if (network !== undefined) {
              if (
                event.target.value.toLowerCase() ===
                elem[x].operator.toLowerCase()
              ) {
                console.log("voila", elem[x].client_id);
                // setMonNumero(elem[x].merchantNumber);
                setCredentials(elem[x].client_id);
                ListOfCredential(elem[x].client_id);
              }
            }
          }
        }
      }
    );

    // FetchData(Endpoints.User.getUserMerchantNumbers).then((res) => {
    //   setNumber(res);
    //   setNumberLoading(false);
    //   // console.log("moo po", network );

    //   if (res.data.datas.items.length !== 0) {
    //     // console.log("voila toi nous maj",event.target.value);
    //     for (let x in res.data.datas.items) {
    //       let elem = res.data.datas.items;
    //       if (network !== undefined) {
    //         if (
    //           event.target.value.toLowerCase() ===
    //           elem[x].operator.toLowerCase()
    //         ) {
    //           // console.log("voila toi nous", elem[x].operator.toLowerCase());
    //           setMonNumero(elem[x].merchantNumber);
    //         }
    //       }
    //     }
    //   }
    // });
  }

  function ListOfOperator(pays) {
    // console.log('yoo')
    // console.log("pays", pays);
    // console.log("pays.target.value",pays.target.value)

    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;
    // console.log("lopetateur", lopetateur)

    if (pays.target) {
      FetchData(
        // Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
        Endpoints.Subscriptions.getOperators + "?country=" + lopetateur
      ).then((res) => {
        // setchosenPays(pays.target.value);
        // console.log("lopetateur res-->", lopetateur);
        setchosenPays(lopetateur);
        setlistOperators(res);
      });
    }
  }

  const handleIplist = (e, ip) => {
    // console.log("le e",e)
    // console.log("le ip",ip)
    handleOpen1();
    setDeleteIp({ e, ip });
  };

  const handleToggleRowSelection = (id) => {
    const updatedData = tableData.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setTableData(updatedData);
  };

  const handleToggleSelectAll = () => {
    const updatedData = tableData.map((item) => ({
      ...item,
      selected: !item.selected,
    }));
    setTableData(updatedData);
  };

  const handleDelete = () => {
    const updatedData = tableData.filter((item) => !item.selected);
    setTableData(updatedData);
  };

  const handleCclose = () => {
    setIsActive(!isActive);
    setShow3(false);
    setShow(false);
  };

  const handleClose = () => {
    setIsActive(!isActive);
    setShow(false);
  };

  const handleCclose3 = () => {
    setIsActive(!isActive);
    setShow3(false);
  };

  const handleCclose1 = ({}) => {
    setIsActive(!isActive);
    setShow3(false);
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
    // setIsActive(!isActive);
  };

  const handleOpen3 = () => {
    setShow3(true);
    setIsActive(!isActive);
  };

  const handleOpen1 = () => {
    setShow1(true);
    setIsActive1(!isActive1);
  };
  const handleCclose2 = () => {
    setIsActive1(!isActive1);
    setShow1(false);
  };

  const handleChange = (newTags) => {
    setTag(newTags);
  };

  const handleInput = (e) => {
    setData(e.target.value);
  };

  const GetAllMyLisOfIpWhiteList = () => {
    PutData(Endpoints.UsersProfile.getListOfAllIp).then((res) => {
      // console.log("Mon GetAllMyLisOfIpWhiteList", res.data.responseMessage);
      setAllIpLists(res.data.responseMessage);
    });
  };
  const clientId = credentials;
  const ipAdresse = data;

  const datas = {
    clientId,
    ipAdresse,
  };

  const datasDelete = {
    deleteIp,
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (datas.ipAdresse === "" || datas.ipAdresse === undefined) {
      Swal.fire({
        position: "top-end",
        text: "Ajouté votre Ip Adresse",
        icon: "warning",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    } else {
      const ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;

      if (!ipRegex.test(datas.ipAdresse)) {
        setLoading(false);
        // setIsValid(false);
        Swal.fire({
          position: "top-end",
          // title: "success!",
          text: "Ip Adresse non conforme",
          icon: "warning",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      } else {
        try {
          const { data: status } = await AddwhitelisteApi(datas);

          if (status.responseMessage === "IP NON VALID") {
            setLoading(false);

            Swal.fire({
              position: "top-end",
              title: "Echec",
              text: status.responseMessage,
              icon: "error",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
              toast: true,
            });
            setTimeout(() => {
              handleCclose();
              inf();
            }, 4000);
          } else {
            setLoading(false);
            Swal.fire({
              position: "top-end",
              title: "success!",
              text: "Successfull",
              icon: "success",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
              toast: true,
            });
            setTimeout(() => {
              handleCclose();
              GetAllMyLisOfIpWhiteList();
              inf();
            }, 3000);
          }

          setLoading(false);
        } catch (error) {
          // console.log("error server",error)
          setTimeout(() => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Internal Server Error",
              showConfirmButton: false,
              timer: 5500,
            });
          }, "100");
          setLoading(false);
        }
        setLoading(false);
      }
      setLoading(false);
    }
    setLoading(false);
  }

  async function handleDeleteIp(e) {
    e.preventDefault();
    setLoading(true);
    if (
      datasDelete.deleteIp.ip === "" ||
      datasDelete.deleteIp.ip === undefined
    ) {
      // setLoading(true);
      Swal.fire({
        position: "top-end",
        // title: "success!",
        text: "Ajouté votre Ip Adresse",
        icon: "warning",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    } else {
      try {
        const { data: user, status } = await DeleteApi(datasDelete);

        if (status === 200) {
          Swal.fire({
            position: "top-end",
            title: "success!",
            text: "Successfull",
            icon: "success",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
            toast: true,
          });
          setTimeout(() => {
            handleCclose2();
            GetAllMyLisOfIpWhiteList();
            inf();
          }, 4000);
        }

        setLoading(false);
      } catch (error) {
        // console.log("error server",error)
        setTimeout(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Internal Server Error",
            showConfirmButton: false,
            timer: 5500,
          });
        }, "100");
        setLoading(false);
      }
    }
    setLoading(false);
  }

  const allResponseMessages = allIpLists
    .map((response) => response.responseMessage)
    .flat();

  // console.log("deleteIp",deleteIp)
  // console.log("datasDelete",datasDelete)
  // datasDelete
  // console.log("allIpLists",allIpLists)

  function handleEditToast  () {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  
    Toast.fire({
      icon: "error",
      title: `${Infos.msg.enab}`,
    });
  }

  return (
    <div className="lg:px-20">
      <div className="h-20   flex justify-center text-center">
        <span className="mt-10" style={{ fontSize: 30 }}>
          {Infos.msg.Whitelist}
        </span>
      </div>
      <div className="w-full  relative overflow-hidden  mx-auto my-10">
        <div className="flex gap-4 justify-end sm:rounded-lg ">

        </div>

        <div className="mt-4 lg border-none bg-white md:pl-2 md:pr-4 md:py-4 rounded-md flex justify-between items-center ">
          <div className="flex gap-4">
            <Icon
              icon="carbon:ip"
              height={30}
              style={{ color: "orange", fontSize: "20px" }}
            />
            <p className=" flex  text-opacity-90  pt-1 ">
              {allResponseMessages?.length} IP address listed
            </p>
          </div>

          {encryptStorage.getItem("qosUser")?.isUsing2Fa == false ||
          encryptStorage.getItem("qosUser")?.isUsing2Fa == null ? (
            <button
            onClick={handleOpen}
            className="flex justify-around bg-qosorange  min-w-max w-44  p-2 text-white rounded-sm font-medium"
          >
            {Infos.msg.Add}
            <Icon
              icon="ri:add-line"
              height={24}
              style={{ color: "white", fontSize: "20px" }}
            />
          </button>
          ) : (
            <button
              onClick={handleOpen3}
              className="flex justify-around bg-qosorange  min-w-max w-44  p-2 text-white rounded-sm font-medium"
            >
              {Infos.msg.Add}
              <Icon
                icon="ri:add-line"
                height={24}
                style={{ color: "white", fontSize: "20px" }}
              />
            </button>
          )}
        </div>

        <div className=" mt-1 bg-white ">


          <Table striped hover bordered className="custom-table">
            <tbody className="mt-10 md:pr-10 md:pl-4">
              {allIpLists.map((item, index) =>
                item.responseMessage.map((ip, idx) => (
                  <tr key={index + "_" + idx} className="ml-10">
                    <td className="flex gap-4">
                      <Icon
                        icon="carbon:ip"
                        height={30}
                        style={{ color: "orange" }}
                      />
                      <p className="pt-1">{ip}</p>
                      <p className="pl-5 pt-1">{item.operator}</p>{" "}
                      {/* Display operator */}
                    </td>
                    {/* <td className="justify-end">
                      <Icon
                        className="cursor-pointer"
                        icon="material-symbols:delete-outline"
                        height={24}
                        onClick={() => handleIplist(item.clientID, ip)}
                        style={{ color: "#FF5403", fontSize: "20px" }}
                      />
                    </td> */}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal
        show={show3}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Title
          className="flex justify-end items-end align-end pr-3"
          onClick={handleCclose1}
        >
          <button style={{ marginRight: 15, marginTop: 10 }}>X</button>
        </Modal.Title>
        <Modal.Body>
          <div>
            <div className="w-full   p-2">
              <div className="max-w-full xl relative overflow-hidden  mt-9 mx-auto my-2 sm:rounded-lg ">
                <div className="flex gap-4 justify-end sm:rounded-lg ">
                  <div>
                    {doloading ? (
                      <span className="text-qosText ">Loading...</span>
                    ) : (
                      <select
                        id="operators"
                        onChange={handleChangess}
                        name="operators"
                        autoComplete="operators"
                        className="h-18  bg-white bg-opacity-70 text-black text-mmd px-4 md:w-40 min-w-max "
                      >
                        <option value="" selected hidden className="text-black">
                          Select operator
                        </option>
                        {/* <option value=""  selected hidden >{listOperators[0]}</option> */}
                        {listOperators?.data?.datas?.items?.map((item) => (
                          <option key={item.index}>{item}</option>
                        ))}
                      </select>
                    )}
                  </div>

                  <div>
                    <select
                      onChange={(e) => ListOfOperator(e)}
                      className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd "
                    >
                      {doloading
                        ? "loading.."
                        : Infocountries?.data?.datas?.items?.map((item) => {
                            // console.log("Infocountries Infocountries", item);
                            return (
                              <option
                                className="text-qosorange m-1 w-10"
                                key={item.index}
                              >
                                {item === "TOGO"
                                  ? "🇹🇬"
                                  : item === "BENIN"
                                  ? "🇧🇯"
                                  : item === "IVORY_COST" ||
                                    item === "IVORY_COAST"
                                  ? "🇨🇮"
                                  : ""}
                              </option>
                            );
                          })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-4 ">
                <span className="text-dark text-left " style={{ fontSize: 18 }}>
                  IP Whitelist
                </span>
              </div>

              <div className=" py-1">
                <input
                  style={styles.input}
                  onChange={handleInput}
                  className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                  // value={data.password}
                  name="ipAdresse"
                  color="red"
                  type="text"
                  required
                  placeholder="Add your IP Address"
                />
              </div>

              <div className="w-full flex  mt-20 justify-center items-center p-1">
                <div className="w-full p-1 mt-4 mb-2 ">
                  <button
                    disabled={isLoading}
                    onClick={handleSubmit}
                    style={styles.button}
                    className={` w-full xl:w-full lg:w-full rounded-md  flex justify-center items-center  text-white text-opacity-80  ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    }  p-3 btn-sm mx-auto mt-14`}
                  >
                    {isLoading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      <div className="flex gap-2  justify-center align-center">
                        <span>{Infos.msg.Add}</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal  */}
      <Modal
        show={show1}
        onHide={handleCclose1}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Title
          className="flex justify-end items-end align-end pr-3"
          onClick={handleCclose2}
        >
          <button style={{ marginRight: 15, marginTop: 10 }}>X</button>
        </Modal.Title>
        <Modal.Body>
          <div>
            <div className="w-full   p-2">
              <div className="flex justify-center mt-4 ">
                <span
                  className="text-dark text-center "
                  style={{ fontSize: 18 }}
                >
                  {Infos.msg.delete}
                </span>
              </div>

              <div className="w-full flex  mt-1 justify-center items-center p-1">
                <div className="w-full p-1 mt-4 mb-2 flex gap-2">
                  <button
                    // disabled={isLoading}
                    onClick={handleCclose2}
                    style={styles.button}
                    className={` w-full xl:w-full lg:w-full rounded-md bg-qosorange flex justify-center items-center  text-white text-opacity-80   p-3 btn-sm mx-auto mt-14`}
                  >
                    {Infos.msg.no}
                  </button>
                  <button
                    disabled={isLoading}
                    onClick={handleDeleteIp}
                    style={styles.button}
                    className={` w-full xl:w-full lg:w-full rounded-md  flex justify-center items-center  text-white text-opacity-80  ${
                      isLoading ? "bg-coolGray-300" : "bg-qosred"
                    }  p-3 btn-sm mx-auto mt-14`}
                  >
                    {isLoading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      <div className="flex gap-2  justify-center align-center">
                        <span>{Infos.msg.yes}</span>
                        {/* <span className="fi-rr-arrow-right mt-1"></span> */}
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalError show={show} handleClose={handleClose} handleCclose1={handleCclose1} />
    </div>
  );
}

const styles = {
  // button:{
  //   // width:680,
  //   height:"28",
  //   borderRadius:4,
  // }
  input: {
    // width:680,
    // height:"42px",
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    // borderRadius:8,
  },
};
